import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './lang/i18n';
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// const helmet = Helmet.renderStatic();

// alert("okay");
// console.log("helmet Data ", helmet);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from "react";
// import ReactDOMServer from "react-dom/server";
// import express from "express";
// import { Helmet } from "react-helmet";

// const PORT = process.env.PORT || 3008;
// import App from "./App";

// const app = express();

// app.get("/*", (req, res) => {
//   const appString = ReactDOMServer.renderToString(<App />);
//   const helmet = Helmet.renderStatic();

//   const html = `<!DOCTYPE html>
//     <html lang="en">
//       <head>
//         ${helmet.title.toString()}
//         ${helmet.meta.toString()}
//       </head>
//       <body>
//         <div id="root">
//           ${appString}
//         </div>
//       </body>
//     </html>
//   `;

//   res.send(html);
// });

// app.listen(PORT);
