import React from "react";
import { useTranslation } from "react-i18next";

import "./Footer-pages.css";
import { ReturnPolicydata } from "../../Container/2FA-Alldata";
import { Helmet } from "react-helmet";

const Return_policy = () => {
  const { t } = useTranslation();
  
  return (
    <div className="bg-white">
         <Helmet>
       <meta name="title" content="Return Policy | 2FAPVA" />
        <meta name="description" content="Find out about our Return Policy and the process for returning or exchanging products purchased on our website. Learn about our policies on refunds, restocking fees, and more." />
        <meta name="keywords" content="virtual phone number refund policy, return virtual phone number, returning virtual phone numbers, refund virtual phone number, virtual phone number return policy, virtual phone number cancellation policy, virtual phone number exchange policy, virtual phone number money back guarantee, virtual phone number return & refund policy, virtual phone number return rules, returning a virtual phone number to the provider, refunding" />
      </Helmet>
      <div className="container-lg same-space-bottom- Returnpage table-card table-min-height">
        <h1 className="text-center title2">{`${t('return-policy-data.main_title')}`}</h1>

        <div className="privacypage">
          <p className="desone">{`${t('return-policy-data.desone')}`}</p>
          <p className="conditions">{`${t('return-policy-data.destwo')}`}</p>
          <p className="retuen1">{`${t('return-policy-data.desthree')}`}</p>
          <p className="retuen1">{`${t('return-policy-data.desfour')}`}</p>
          <p className="retuen1">{`${t('return-policy-data.desfive')}`}</p>
          <p className="retuen1">{`${t('return-policy-data.dessix')}`}</p>
        </div>

          {/* <div className="privacy-policy-main footerpages-height">
      <div className="footerall-content">
        <h4>Comming Soon</h4>
      </div>
    </div> */}
        </div>
      </div>
  
  );
};

export default Return_policy;
