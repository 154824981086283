import "./ArticlesPage.css";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ArticleJSON from "./articlesData.json";

const ARTICLES_PER_PAGE = 6;
const PAGES_TO_DISPLAY = 10;

const ArticlesPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPageParam = parseInt(searchParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(currentPageParam);

  useEffect(() => {
    setCurrentPage(currentPageParam);
  }, [currentPageParam]);

  const totalPages = Math.ceil(ArticleJSON.length / ARTICLES_PER_PAGE);

  const startArticleIndex = (currentPage - 1) * ARTICLES_PER_PAGE;
  const endArticleIndex = startArticleIndex + ARTICLES_PER_PAGE;
  const displayedArticles = ArticleJSON.slice(startArticleIndex, endArticleIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    searchParams.set("page", newPage);
    // Replace the current URL without reloading the page
    window.history.replaceState({}, "", `${location.pathname}?${searchParams}`);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const startIndex = Math.max(0, currentPage - Math.floor(PAGES_TO_DISPLAY / 2));
  const endIndex = Math.min(startIndex + PAGES_TO_DISPLAY, totalPages);
  const displayedPageNumbers = pageNumbers.slice(startIndex, endIndex);

  return (
    <div className="paymentBG">
      <div className="articles-page">
        <h1 className="h1">Articles</h1>
        <p className="h1des">
          Easy Two-Factor Verification: Bypass Phone SMS Verification on Any
          Website with Virtual Numbers
        </p>
        <div className="articles-list">
          {displayedArticles.map((article, index) => (
            <div className="article" key={article.id}>
              <img
                src={article.img && article.img.startsWith('i') ? `/${article.img}` : article.img}
                loading="lazy"
                width="375"
                height="155"
                alt={article.title}
              />
              <h2>{article.title}</h2>
              <p className="article-text">{article.description}</p>
              <Link to={`${article.slug}`}>
                <button>Read more</button>
              </Link>
            </div>
          ))}
        </div>
        <ul className="pagination">
          {displayedPageNumbers.map((number) => (
            <li key={number} className={currentPage === number ? "active" : null}>
              <Link to="#" onClick={() => handlePageChange(number)}>
                {number}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ArticlesPage;
