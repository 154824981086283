import React from "react";
import { useTranslation } from "react-i18next";

import "./Footer-pages.css";
import { suppoPrivacyPolicyrtdata } from "../../Container/2FA-Alldata";
import { Helmet } from "react-helmet";

const Privacy_policy = () => {
  const { t } = useTranslation();

  return (
    
    <div className="bg-white">
       <Helmet>
       <meta name="title" content="Privacy Policy | 2FAPVA" />
        <meta name="description" content="Learn about our commitment to protecting your personal information and data privacy on our Privacy Policy page. Stay informed on how we collect, use, and secure your information." />
        <meta name="keywords" content="Privacy policy for virtual phone number service, Virtual number privacy policy, Data protection for virtual phone numbers, Personal information and virtual phone numbers, Virtual phone number data privacy, SMS verification and privacy, GDPR compliant virtual phone number service, Information security for virtual numbers, Data collection for virtual phone numbers, Virtual phone number data storage, Virtual phone number data usage, Virtual phone number data sharing, Third-party providers and virtual phone numbers, Virtual phone number data retention, Virtual phone number data deletion, User consent for virtual phone numbers, Virtual phone number encryption, Secure servers for virtual phone numbers, Virtual phone number user rights, Virtual phone number opt-out options, Virtual phone number data breaches, Virtual phone number compliance, Virtual phone number privacy rights, Virtual phone number data protection laws, Virtual phone number data privacy regulations," />
      </Helmet>
      <div className="container-lg  table-card table-min-height same-space-bottom-">
        <h1 className="text-center title2">
          {`${t("privacy-policy.main_title")}`}
        </h1>
        <div className="privacypage">
          <p className="desone">{`${t("privacy-policy.desone")}`}</p>
          <h2 className="tilte-privacy">
            {`${t("privacy-policy.contact_us")}`}
          </h2>
          <p className="desthree">{`${t("privacy-policy.desthree")}`}</p>
          <p className="destwo">{`${t("privacy-policy.destwo")}`}</p>
          <p className="desthree">{`${t("privacy-policy.desthree")}`}</p>
          <h2 className="tilte-privacy">{`${t("privacy-policy.title1")}`}</h2>
          <p className="desfour">{`${t("privacy-policy.desfour")}`}</p>
          <h2 className="tilte-privacy">{`${t("sharing-your-information.title2")}`}</h2>
          <p className="desfive">{`${t("sharing-your-information.desfive")}`}</p>
          <p className="dessix1">{`${t("sharing-your-information.dessix1")}`}</p>
          <p className="dessix1">{`${t("sharing-your-information.dessix2")}`}</p>
          <p className="dessix1">{`${t("sharing-your-information.dessix3")}`}</p>
          <h2 className="tilte-privacy">{`${t("cookies-processing.title")}`}</h2>
          <p className="dessix1">{`${t("cookies-processing.cookiesdes1")}`}</p>
          <p className="dessix1">{`${t("cookies-processing.cookiesdes2")}`}</p>
          <p className="dessix1">{`${t("cookies-processing.cookiesdes3")}`}</p>
          <h2 className="tilte-privacy">{`${t("cookies-processing.title4")}`}</h2>
          <p className="dessix1">{`${t("cookies-processing.Securitydes")}`}</p>
          <h2 className="tilte-privacy">{`${t("cookies-processing.title5")}`}</h2>
          <p className="dessix1">{`${t("cookies-processing.Changesdes1")}`}</p>
          
          <h2 className="tilte-privacy">{`${t("cookies-processing.title7")}`}</h2>
          <p className="dessix1">{`${t("cookies-processing.Contactdes")}`}</p>
          <a href={`mailto:${t("cookies-processing.Email")}`}>
            {`${t("cookies-processing.Email")}`}
          </a>
          .
        </div>
        {/* <div className="privacy-policy-main footerpages-height">
        <div className="footerall-content">
          <h1 className="top-heading"></h1>
          <h4>Comming Soon</h4>
        </div>
      </div> */}
        </div>
      </div>
  
  );
};

export default Privacy_policy;
