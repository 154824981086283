import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  balanceHistoryCall,
  changePassCall,
  forgerPassCall,
  keyCall,
} from "../../calls";
import ClipLoader from "react-spinners/ClipLoader";
import Error from "../../components/Error/Error";
import Spinner from "react-bootstrap/Spinner";
import Login from "../../components/login/login";
import Signup from "../../components/signup/signup";
import { useTranslation } from "react-i18next";


const ForgetPassword = (props) => {
  const {
    setShowModal,
    setShowModal2,
    setIsLoggedIn,
    showModal,
    showModal2,
    isLoggedIn,
    setBalance,
    showErrorModal,
    setShowErrorModal,
    errorMsg,
    setErrorMsg,
    loader,
    setLoader,
  } = props;

  const [email, setEmail] = useState("");

  const [error, setError] = useState([]);

  const [success, setSuccess] = useState("");

  const showError = () => {
    return (
      <div className="row">
        <Error
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          errorMsg={errorMsg}
        ></Error>
      </div>
    );
  };

  const handleDataChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    setLoader(false);
    setError([]);
    e.preventDefault();
    forgerPassCall(email)
      .then((response) => {
        setLoader(true);
        const { data } = response;
        setSuccess("Email Sent Successfully ");

        setEmail();
      })
      .catch((err) => {
        setLoader(true);
        setShowErrorModal(true);
        setErrorMsg([err.response.data.email]);
      });
  };
  const { t } = useTranslation();
  if (!isLoggedIn) {
    return (
      <div className="container-lg forgetpasswords table-min-heightforget ">
        {!loader ? (
          <Spinner
            animation="border"
            role="status"
            className="loader"
          ></Spinner>
        ) : (
          <></>
        )}

        <div className="forgetpasswordsform">
          <div className="row margin-top-10 text-align-start font-size-small padding-top-10">
            <h1 className="title2Forget ">{`${t('overview.forget-pass')}`}</h1>
          </div>
          <div className="form_sec">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label for="old_password" className="form-label">
                {`${t('overview.enter-email')}`}
                </label>
                <input
                  required
                  value={email}
                  onChange={handleDataChange}
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                />
              </div>

              <div className="row">
                <h5 className="success_msg text-center">{success}</h5>
              </div>
              <div className="text-align-end">
                <button type="submit" className="btn Forgetbtn">
                {`${t('overview.change-pass')}`}
                </button>
              </div>
            </form>
          </div>
        </div>

        {showError()}
      </div>
    );
  } else {
    return <Navigate replace to="/" />;
  }
};

export default ForgetPassword;
