import "./App.css";

import { useState } from "react";
import { useBasename } from "./lang/languages";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NavBar from "./components/navbar";
import HomePage from "./pages/home/home";
import BalanceHistory from "./pages/balanceHistory/balanceHistory";
import OrderList from "./pages/orderList/orderList";
import ChangePass from "./pages/changePassword/changePassword";
import ForgetPassword from "./pages/forgetPassword/forgetPassword";
import ResetPassword from "./pages/reset-password/reset-password";
import FAQ from "./pages/help/help";
import Login from "./components/login/login";
import Signup from "./components/signup/signup";
import Deposit from "./pages/deposit/deposit";
import Footer from "./pages/footer/Footer";
import Payment_policy from "./pages/footer-pages/Payment-policy";
import Privacy_policy from "./pages/footer-pages/Privacy-policy";
import Return_policy from "./pages/footer-pages/Return-policy";
import Terms_use from "./pages/footer-pages/Terms-use";
import Freenum from "./pages/freenum/freenum";
import Thanks from "./pages/thanks-page/thanks";
import Error from "./pages/thanks-page/error";
import ThanksPage from "./components/ThanksPage/ThanksPage";
import EmailVerify from "./components/VerifyEmail/EmailVerify";
import CookiesModal from "./components/Cookies/CookiesModal.jsx";
import ArticlesPage from "./blog/ArticlesPage";
import Rent from "./pages/rent/rent";
import NoteFounfPage from "./pages/404/404";
import SingleArticle from "./blog/SingleArticle";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorage.getItem("token", ""))
  );

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [balance, setBalance] = useState(localStorage.getItem("balance"));
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [loader, setLoader] = useState(true);

  const updateBalance = (balance) => {
    setBalance(balance);
    localStorage.setItem("balance", balance);
  };

  return (
    <div className="App ">
      {!loader ? <div className="overlay"></div> : <></>}
      <Login
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        setShowModal={setShowModal}
        showModal={showModal}
        setBalance={updateBalance}
        loader={loader}
        setLoader={setLoader}
        setShowModal2={setShowModal2}
        showModal2={showModal2}
      />
      <Signup
        isLoggedIn={isLoggedIn}
        setShowModal={setShowModal}
        showModal={showModal}
        setIsLoggedIn={setIsLoggedIn}
        setShowModal2={setShowModal2}
        showModal2={showModal2}
        loader={loader}
        setLoader={setLoader}
      />
      <CookiesModal />

      <header>
        <NavBar
          isLoggedIn={isLoggedIn}
          setShowModal={setShowModal}
          setShowModal2={setShowModal2}
          setIsLoggedIn={setIsLoggedIn}
          balance={balance}
          loader={loader}
          setLoader={setLoader}
        />
      </header>
      <Routes>
        <Route
          path={`/`}
          exact
          element={
            <HomePage
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              showModal={showModal}
              showModal2={showModal2}
              isLoggedIn={isLoggedIn}
              setBalance={updateBalance}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/overview"
          path={`/overview`}
          element={
            <HomePage
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              showModal={showModal}
              showModal2={showModal2}
              isLoggedIn={isLoggedIn}
              setBalance={updateBalance}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/instructions"
          path={`/instructions`}
          element={
            <HomePage
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              showModal={showModal}
              showModal2={showModal2}
              isLoggedIn={isLoggedIn}
              setBalance={updateBalance}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/support"
          path={`/support`}
          element={
            <HomePage
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              showModal={showModal}
              showModal2={showModal2}
              isLoggedIn={isLoggedIn}
              setBalance={updateBalance}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/help"
          path={`/help`}
          element={
            <FAQ
              isLoggedIn={isLoggedIn}
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              balance={balance}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/free-numbers"
          path={`/free-numbers`}
          element={
            <Freenum
              isLoggedIn={isLoggedIn}
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              balance={balance}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
       
        <Route
          // path="/balance-history"
          path={`/balance-history`}
          element={
            <BalanceHistory
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route path={`/payment-policy`} element={<Payment_policy />} />
        <Route path={`/privacy-policy`} element={<Privacy_policy />} />
        <Route path={`/return-policy`} element={<Return_policy />} />
        <Route path={`/terms-use`} element={<Terms_use />} />

        <Route
          // path="/forget-password"
          path={`/forget-password`}
          element={
            <ForgetPassword
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
              loader={loader}
              setLoader={setLoader}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
            />
          }
          setShowModal={setShowModal}
          setShowModal2={setShowModal2}
          setIsLoggedIn={setIsLoggedIn}
          showModal={showModal}
          showModal2={showModal2}
          isLoggedIn={isLoggedIn}
        />
        <Route
          // path="/forget-password"
          path={`/rent`}
          element={
            <Rent
              setBalance={updateBalance}
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
              loader={loader}
              setLoader={setLoader}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
            />
          }
          setShowModal={setShowModal}
          setShowModal2={setShowModal2}
          setIsLoggedIn={setIsLoggedIn}
          showModal={showModal}
          showModal2={showModal2}
          isLoggedIn={isLoggedIn}
        />
        <Route
          // path="/reset-password/:tokens/:userid"
          path={`/reset-password/:tokens/:userid`}
          element={
            <ResetPassword
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
              loader={loader}
              setLoader={setLoader}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
            />
          }
          setShowModal={setShowModal}
          setShowModal2={setShowModal2}
          setIsLoggedIn={setIsLoggedIn}
          showModal={showModal}
          showModal2={showModal2}
          isLoggedIn={isLoggedIn}
        />
        <Route
          // path="/change-password"
          path={`/change-password`}
          element={
            <ChangePass
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/deposit"
          path={`/deposit`}
          element={
            <Deposit
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/order-list"
          path={`/order-list`}
          element={
            <OrderList
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
              setBalance={updateBalance}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/Thanks"
          path={`/Thanks`}
          element={
            <Thanks
              isLoggedIn={isLoggedIn}
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              balance={balance}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/Thanks"
          path={`/error`}
          element={
            <Error
              isLoggedIn={isLoggedIn}
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              balance={balance}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/thank-you"
          path={`/thank-you`}
          element={
            <ThanksPage
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              showModal={showModal}
              showModal2={showModal2}
              isLoggedIn={isLoggedIn}
              setBalance={updateBalance}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
        <Route
          // path="/verify-email/:token/:uid"
          path={`/verify-email/:token/:uid`}
          element={
            <EmailVerify
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              showModal={showModal}
              showModal2={showModal2}
              isLoggedIn={isLoggedIn}
              setBalance={updateBalance}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />

        <Route path={`/blog`} element={<ArticlesPage />} />
        <Route path={`/blog/:slug`} element={<SingleArticle />} />

        <Route path="*" element={<NoteFounfPage />} />
        <Route
          path=""
          element={
            <HomePage
              setShowModal={setShowModal}
              setShowModal2={setShowModal2}
              setIsLoggedIn={setIsLoggedIn}
              showModal={showModal}
              showModal2={showModal2}
              isLoggedIn={isLoggedIn}
              setBalance={updateBalance}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              loader={loader}
              setLoader={setLoader}
            />
          }
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default () => {
  const { basename } = useBasename();
  return (
    <BrowserRouter {...{ basename }}>
      <App />
    </BrowserRouter>
  );
};
