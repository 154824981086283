import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-modal";
import "./Error.css";
import { useTranslation } from "react-i18next";

Modal.setAppElement("#root");


const Error = (props) => {
  const { showErrorModal, setShowErrorModal, errorMsg } = props;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      "background-color": "#f8d7da",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      borderBottomLeftRadius: 20,
    },
  };

  // console.log("IN ERROR MODAL");
  const { t } = useTranslation();
  if (showErrorModal) {
    return (
      <div className="row ">
        <Modal
          isOpen={showErrorModal}
          onRequestClose={() => setShowErrorModal(false)}
          style={customStyles}
          contentLabel="Error"
        >
          <Alert
            variant="danger"
            onClose={() => setShowErrorModal(false)}
            dismissible
          >
            <Alert.Heading>{`${t('overview.error')}`}</Alert.Heading>
            <p>{errorMsg}</p>
          </Alert>
        </Modal>
      </div>
    );
  }
};

export default Error;
