import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Footer.css";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer-main">
      <div className="footer-content">
        <p>
          {`${t("overview.rights-reserved")}`}{" "}
          <Link className="link-decoration"  to={`/free-numbers`}>{`${t("overview.freenum")}`}</Link> 
          {" "}
          |{" "}
          <Link className="link-decoration" to={`/privacy-policy`}>
            {`${t("overview.privacypolicy")}`}
          </Link>{" "}
          |{" "}
          <Link className="link-decoration" to={`/terms-use`}>
            {`${t("overview.terms-use")}`}
          </Link>{" "}
          |{" "}
          <Link className="link-decoration" to={`/payment-policy`}>
            {`${t("overview.payment-policy")}`}
          </Link>{" "}
          |{" "}
          <Link className="link-decoration" to={`/return-policy`}>
            {`${t("overview.return-policy")}`}
          </Link>
          {" "}
          |{" "}
          <Link className="link-decoration" to={`/help`}>
            FAQ
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;
