import React, { useEffect, useState, CSSProperties } from "react";
import successIcon from '../../images/close.png';
import "./thanks.css";
import { useTranslation } from "react-i18next";

const Error = (props) => {
  const [isLoggedInUser, setIsLoggedInUser] = useState("");

  useEffect(() => {
    setIsLoggedInUser(Boolean(localStorage.getItem("token", "")));
  }, [isLoggedInUser]);

  const { t } = useTranslation();

  // console.log("overview_fourth", overview_fourth);
  return (
    <div className="thanksBG">
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-12">
            <div className="bg-white thanks-bg pt-5 pb-5 px-2">
              <img
                alt="thankyou"
                className="success-icon mt-5"
                src={successIcon}
              />
              <h1 className="text-center success-text">Payment Failed !</h1>
              <p className="success-description text-center">The payment was unsuccessful due to an abnormality. Please try again or use another payment method.</p>
              <div className="d-flex justify-content-center pb-5 mb-5">
                <a href="/deposit" className="btn btn-primary btn-home-page">try again</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
