import React, { useEffect, useState, CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import "./Overview.css";
import {
  overview_first,
  overview_second,
  overview_third,
  overview_fourth,
} from "../../Container/2FA-Alldata";

const Overview = (props) => {
  const { t } = useTranslation();
  const [isLoggedInUser, setIsLoggedInUser] = useState("");

  useEffect(() => {
    setIsLoggedInUser(Boolean(localStorage.getItem("token", "")));
  }, [isLoggedInUser]);

  console.log("isLoggedInUser", isLoggedInUser);
  const {
    isLoggedIn,
    setShowModal,
    setShowModal2,
    setIsLoggedIn,
    balance,
    loader,
    setLoader,
  } = props;

  // console.log("overview_fourth", overview_fourth);
  return (
    <div className="overview-main">
      <div className="overview-first-section">
        <h1 className="top-heading">{`${t(
          "overview.overview_first.title"
        )}`}</h1>
        <div className="first-description">
          {/* <p>{overview_first.firstline}</p> */}
          <p>{`${t("overview.overview_first.firstline")}`}</p>
        </div>
        <div className="first-description">
          <p>{`${t("overview.overview_first.secondline")}`}</p>
        </div>
        <div className="third-description">
          <p
            dangerouslySetInnerHTML={{
              __html: t("overview.overview_first.thirdline"),
            }}
          ></p>
        </div>
      </div>

      <div className="overview-second-section">
        <h2 className="overview-all-headings">{`${t(
          "overview.overview_second.title"
        )}`}</h2>
        <div className="steps-description">
          <p>{`${t("overview.overview_second.description")}`}</p>
        </div>
        {/* {overview_second.stepdata.map((data) => (
          <>
            <div className="all-steps">
              <img src={data.steplogo} />
              <p>{data.stepname}</p>
            </div>
          </>
        ))} */}
        <div className="all-steps">
          <img alt="step1" height="35px" width="35px" src={overview_second.stepdata[0].steplogo} />
          <p>{`${t("overview.overview_second.stepdata.stepname1")}`}</p>
        </div>
        <div className="all-steps">
          <img alt="step2" height="35px" width="35px" src={overview_second.stepdata[1].steplogo} />
          <p>{`${t("overview.overview_second.stepdata.stepname2")}`}</p>
        </div>
        <div className="all-steps">
          <img alt="step3" height="35px" width="35px" src={overview_second.stepdata[2].steplogo} />
          <p>{`${t("overview.overview_second.stepdata.stepname3")}`}</p>
        </div>
        <div className="all-steps">
          <img alt="step4" height="35px" width="35px" src={overview_second.stepdata[3].steplogo} />
          <p>{`${t("overview.overview_second.stepdata.stepname4")}`}</p>
        </div>
        <p
          className="online-numbers"
          dangerouslySetInnerHTML={{
            __html: t("overview.overview_second.destwo"),
          }}
        ></p>
        {/* <p className="online-numbers">{overview_second.destwo}</p> */}
      </div>

      <div className="overview-third-section">
        <h2 className="overview-all-headings">{`${t(
          "overview.overview_third.title"
        )}`}</h2>
        <div className="activation-box">
          {/* {overview_third.activationdata.map((data) => (
            <>
              <div className="activation-rent">
                <img src={data.logo} />
                <span
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></span>
              </div>
            </>
          ))} */}
          <div className="activation-rent">
            <img alt="activations" height="25px" width="25px" src={overview_third.activationdata[0].logo} />
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  "overview.overview_third.activationdata.description1"
                ),
              }}
            ></span>
          </div>
          <div className="activation-rent">
            <img alt="rent" height="25px" width="25px" src={overview_third.activationdata[1].logo} />
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  "overview.overview_third.activationdata.description2"
                ),
              }}
            ></span>
          </div>
        </div>
      </div>

      <div className="overview-fourth-section">
        <h2
          className="overview-all-headings"
          dangerouslySetInnerHTML={{
            __html: t("overview.overview_fourth.title"),
          }}
        ></h2>
        <div className="row discover-content">
          {/* {overview_fourth.discoverdata.map((data) => (
            <>
              <div className="col-md-6 col-sm-6 col-lg-4 col-12 text-center">
                <img src={data.logo} />
                <h1
                  dangerouslySetInnerHTML={{ __html: data.heading }}
                  className="discover-heading"
                ></h1>
              </div>
            </>
          ))} */}
          <div className="col-md-6 col-sm-6 col-lg-4 col-12 text-center">
            <img alt="img1" height="60px" width="80px" src={overview_fourth.discoverdata[0].logo} />
            <h2
              dangerouslySetInnerHTML={{
                __html: t(
                  "overview.overview_fourth.discoverdata.data1.heading"
                ),
              }}
              className="discover-heading"
            ></h2>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-4 col-12 text-center">
            <img alt="img2" height="60px" width="80px" src={overview_fourth.discoverdata[1].logo} />
            <h2
              dangerouslySetInnerHTML={{
                __html: t(
                  "overview.overview_fourth.discoverdata.data2.heading"
                ),
              }}
              className="discover-heading"
            ></h2>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-4 col-12 text-center">
            <img alt="img3" height="60px" width="80px" src={overview_fourth.discoverdata[2].logo} />
            <h2
              dangerouslySetInnerHTML={{
                __html: t(
                  "overview.overview_fourth.discoverdata.data3.heading"
                ),
              }}
              className="discover-heading"
            ></h2>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-4 col-12 text-center">
            <img alt="img4" height="60px" width="80px" src={overview_fourth.discoverdata[3].logo} />
            <h2
              dangerouslySetInnerHTML={{
                __html: t(
                  "overview.overview_fourth.discoverdata.data4.heading"
                ),
              }}
              className="discover-heading"
            ></h2>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-4 col-12 text-center">
            <img alt="img5" height="60px" width="80px" src={overview_fourth.discoverdata[4].logo} />
            <h2
              dangerouslySetInnerHTML={{
                __html: t(
                  "overview.overview_fourth.discoverdata.data5.heading"
                ),
              }}
              className="discover-heading"
            ></h2>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-4 col-12 text-center">
            <img alt="img6" height="60px" width="80px" src={overview_fourth.discoverdata[5].logo} />
            <h2
              dangerouslySetInnerHTML={{
                __html: t(
                  "overview.overview_fourth.discoverdata.data6.heading"
                ),
              }}
              className="discover-heading"
            ></h2>
          </div>
        </div>
        <p
          className="help-support"
          dangerouslySetInnerHTML={{
            __html: t("overview.overview_fourth.discoverdata.desone"),
          }}
        ></p>
        <p
          className="virtual-number"
          dangerouslySetInnerHTML={{
            __html: t("overview.overview_fourth.discoverdata.destwo"),
          }}
        ></p>
        {/* {JSON.stringify(isLoggedInUser)} */}

        {isLoggedIn ? (
          <>
            <div className=""></div>
          </>
        ) : (
          <>
  
            <button
              onClick={(e) => {
                setShowModal(true);
              }}
              className="sign-up-btn"
            >
              {`${t("overview.overview_fourth.discoverdata.button")}`}
            </button>
          
          </>
        )}
      </div>
    </div>
  );
};

export default Overview;
