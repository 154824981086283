import "./LanguagesButton.css";

import { Dropdown } from "react-bootstrap";
import { languages, useLocale, useLanguage } from "../../lang/languages";

function LanguagesDropdown() {
  const { locale } = useLocale();
  const { language } = useLanguage();

  const onClickLanguage = (language) => () => {
    if (locale === language.locale) return;

    window.location.href = window.location.pathname.replace(
      new RegExp(`^\/${locale}`),
      `/${language.locale}`
    );
  };

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" class="dropdown-basic">
        {language.name}
      </Dropdown.Toggle>

      <Dropdown.Menu class="dropdown-menu">
        {languages.map((language) => (
          <Dropdown.Item
            key={language.locale}
            onClick={onClickLanguage(language)}
          >
            {language.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguagesDropdown;
