import React from 'react';
import { useTranslation } from "react-i18next";

import { instructions_one, instructions_two, instructions_three, instructions_four } from '../../Container/2FA-Alldata';
import "./Instructions.css";


const Instructions = () => {
  const { t } = useTranslation();

  return (
    <div className='instructions-main'>
      <div className='instructions-first-section'>
        <h1 className="top-heading">{`${t('instructions.instructions_one.title')}`}</h1>
        <p className='des-content'>{`${t('instructions.instructions_one.description')}`}</p>
      </div>

      <div className='instructions-second-section'>
        <h2 className='instruction-heading'>{`${t('instructions.instructions_two.title')}`}</h2>
        <div className='act-content'>
          {/* <ol>
            {instructions_two.activating.map((item) => (
              <>
                <li>{item.step}</li>
              </>
            ))}
          </ol> */}
          <ol>
            <li>{`${t('instructions.instructions_two.activating.step1')}`}</li>
            <li>{`${t('instructions.instructions_two.activating.step2')}`}</li>
            <li>{`${t('instructions.instructions_two.activating.step3')}`}</li>
            <li>{`${t('instructions.instructions_two.activating.step4')}`}</li>
            <li>{`${t('instructions.instructions_two.activating.step5')}`}</li>
          </ol>
        </div>
      </div>

      <div className='instructions-third-section'>
        <h2 className='instruction-heading'>{`${t('instructions.instructions_three.title')}`}</h2>
        <div className='act-content'>
          <ul>
            {/* {instructions_three.rules.map((item) => (
              <>
                <li>{item.step}</li>
              </>
            ))} */}
            <li>{`${t('instructions.instructions_three.rules.step1')}`}</li>
            <li>{`${t('instructions.instructions_three.rules.step2')}`}</li>
            <li>{`${t('instructions.instructions_three.rules.step3')}`}</li>
            <li>{`${t('instructions.instructions_three.rules.step4')}`}</li>
            <li>{`${t('instructions.instructions_three.rules.step5')}`}</li>
            <li>{`${t('instructions.instructions_three.rules.step6')}`}</li>
            <li>{`${t('instructions.instructions_three.rules.step7')}`}</li>
          </ul>
        </div>
      </div>

      <div className='instructions-fourth-section'>
        <h2 className='instruction-heading'>{`${t('instructions.instructions_four.title')}`}</h2>
        <div className='privacy-content'>
          <ul>
            {/* {instructions_four.privacy.map((item) => (
              <>
                <li>{item.step}</li>
              </>
            ))} */}
            <li>{`${t('instructions.instructions_four.privacy.step1')}`}</li>
            <li>{`${t('instructions.instructions_four.privacy.step2')}`}</li>
          </ul>
        </div>
        <p>{`${t('instructions.instructions_four.desone')}`}</p>
        <p>{`${t('instructions.instructions_four.destwo')}`}</p>
        <p>{`${t('instructions.instructions_four.desthree')}`}</p>
        <p>{`${t('instructions.instructions_four.desfour')}`}</p>
        <p dangerouslySetInnerHTML={{ __html: t('instructions.instructions_four.desfive') }}></p>
      </div>
    </div>
  )
}

export default Instructions