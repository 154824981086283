import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import Spinner from "react-bootstrap/Spinner";
import { Navigate, NavLink } from "react-router-dom";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useTranslation } from "react-i18next";

// import { GoogleLogin, GoogleLogout } from "react-google-login";
// import { gapi } from "gapi-script";
import {loginCall }from "../../calls";
//   loginCallWithGoogle,
//   loginTokenWithGoogle,

import "./login.css";

Modal.setAppElement("#root");

const axios = require("axios");

const sitekey = "4c3e00a9-8947-458a-8e06-46df45f1ae1e";

const Login = (props) => {
  const { t } = useTranslation();

  const {
    isLoggedIn,
    setIsLoggedIn,
    setShowModal,
    showModal,
    setBalance,
    loader,
    setLoader,
    setShowModal2,
    showModal2,
  } = props;

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [isHidden, setHidden] = useState(true);
  const [loginError, setLoginError] = useState([]);
  // const [token_login, setTokenLogIn] = useState(null);
  const [trySubmit, setTrySubmit] = useState(false);
  const captchaRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        changeVisibility();
      }, 100);
    }

    //change the visibility of the password field
    function changeVisibility() {
      if (!isHidden) {
        document.querySelector("#password").type = "text";
      } else {
        document.querySelector("#password").type = "password";
      }
    }
  }, [isHidden, showModal]);

  const handleHidden = () => {
    setHidden(!isHidden);
  };

  const handleDataChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleVerificationSuccess = (token, eKey) => {
  //   setTokenLogIn(token);
  // };

  const handleSubmit = (e) => {
    // alert("Okayyy");
    e.preventDefault();
    setTrySubmit(true);
   
      setLoader(false);
      loginCall(loginData)
        .then((response) => {
          console.log(response);
          const { data } = response;
          console.log(response);
          setLoader(true);
          localStorage.setItem("token", data.token);
          localStorage.setItem("id",data.user_id)

          // window.location.reload(true);
          setIsLoggedIn(true);
          setBalance(data.balance);
          // alert("Reload");

          setShowModal(false);
          resetStateLogin();
        })
        .catch((err) => {
          setLoader(true);
          if (err?.response?.data?.Message) {
            // setLoginError(["Invalid Email or Password"]);
            setLoginError([err.response.data.Message]);
          } else {
            setLoginError(["Invalid Email or Password"]);
          }
          resetStateLoginError();
        });

  };

  //reset states after successful login
  const resetStateLogin = () => {
    setLoginData({ email: "", password: "" });
    setLoginError([]);
    setHidden(true);
    setTrySubmit(false);
    // setTokenLogIn(null);
    // captchaRef.current.resetCaptcha();
  };

  //reset states after login error
  const resetStateLoginError = () => {
    // setTokenLogIn(null);
    setTrySubmit(false);
    // captchaRef.current.resetCaptcha();
  };

  const errorMsg = loginError.map((item, index) => {
    return (
      <h5 key={index} className="error_msg">
        {item}
      </h5>
    );
  });

  const customStyles = {
    content: {
      boxSizing: "border-box",
      position: "absolute",
      width: "465px",
      maxWidth: "calc(100% - 24px)",
      height: "550px",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      background: "#FFFFFF",
      border: "1px solid #F4F4F4",
      borderRadius: "10px",
    },
  };

  /******** Google Login ***********/
  const clientId =
    "408276211340-7s7qu57gc268o4ok89oicm04648m092s.apps.googleusercontent.com"; // Please change this google client ID

  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: "",
  //     });
  //   };
  //   gapi.load("client:auth2", initClient);
  // });

  // const onLoginSuccces = (res) => {
  //   setTrySubmit(true);
  //   if (!res) {
  //     console.log("No token_login, can't login");
  //   } else {
  //     setLoader(false);
  //     // loginCallWithGoogle(res)
  //       .then((response) => {
  //         console.log(response);
  //         // loginTokenWithGoogle(res?.wt?.cu)
  //           .then((response) => {
  //             console.log("google tocken", response);
  //             const { data } = response;
  //             console.log(response);
  //             setLoader(true);
  //             localStorage.setItem("token", data.token);
  //             setIsLoggedIn(true);
  //             setShowModal(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       })
  //       .catch((err) => {
  //         setLoader(true);
  //         if (err.response && err.response.status === 400) {
  //           setLoginError(["Invalid Email or Password"]);
  //         }
  //         resetStateLoginError();
  //       });
  //   }
  // };

  const onloginFailure = (res) => {
    console.log("login failure", res);
  };
  /******** Google Login ***********/

  return (
    <div className="row log">
      {loader ? (
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          style={customStyles}
          contentLabel="Sign In"
        >
          <div className="login">
            <>
              <div className="title d-flex justify-content-between">
                <span></span>

                <h1 className="modal-title text-center auth-modal-header-text logintitle">
                  {`${t("overview.log-in")}`}
                </h1>
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="btn-close closButton"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {/* <img src="/img/btn1.png" /> */}
                </button>
              </div>
              <div className="text-align-center signup">
                <text>{`${t("overview.account-qst")}`} </text>
                <NavLink
                  onClick={() => {
                    setShowModal(false);
                    setShowModal2(true);
                  }}
                  to=""
                  className={"linkSignUp"}
                >
                  {`${t("overview.sign-up")}`}
                </NavLink>
                <div className="row">{errorMsg}</div>
              </div>

              <form class="row loginsec" onSubmit={handleSubmit}>
                <div className="input-group ">
                  <span className="signupimg">
                    <img
                      src="/img/login.svg"
                      alt="airbnb"
                      width="25px"
                      height="25px"
                      rel="preload"
                    ></img>
                  </span>
                  <input
                    required
                    value={loginData.email}
                    onChange={handleDataChange}
                    type="email"
                    className="form-control inputField"
                    id="email"
                    name="email"
                    placeholder="Email"
                  />
                </div>
                <div className="input-group">
                  <span className="signupimg">
                    <img
                      src="/img/pass.svg"
                      alt="airbnb"
                      width="25px"
                      height="25px"
                      rel="preload"
                    ></img>
                  </span>
                  <input
                    required
                    value={loginData.password}
                    onChange={handleDataChange}
                    type="password"
                    className="form-control inputField"
                    id="password"
                    name="password"
                    placeholder="Password"
                  />
                  {isHidden ? (
                    <i class="bi bi-eye-fill eye" onClick={handleHidden}></i>
                  ) : (
                    <i
                      class="bi bi-eye-slash-fill eye"
                      onClick={handleHidden}
                    ></i>
                  )}
                </div>
{/* 
                <div class="hcaptcha">
                  <HCaptcha
                    id="hcaptcha_login"
                    sitekey={sitekey}
                    onVerify={(token, ekey) =>
                      handleVerificationSuccess(token, ekey)
                    }
                    ref={captchaRef}
                  />
                  {trySubmit ? (
                    <small className="hcaptcha_login_error">
                      {`${t("overview.captcha-required")}`}
                    </small>
                  ) : null}
                </div> */}
                <div className="text-align-end Logbtn">
                  <button
                    type="submit"
                    className="btn btn-primary btnColor w-100"
                  >
                    {`${t("overview.log-in")}`}
                  </button>
                </div>
                <NavLink
                  onClick={() => {
                    setShowModal(false);
                  }}
                  to={`/forget-password`}
                  className={"linkSignUp"}
                >
                  {`${t("overview.forget-pass")}`}
                </NavLink>
              </form>
              {/* <div className="logwith">
                <span className="logtestdiv"></span>
                <span className="logtest">{`${t("overview.or-log")}`}</span>
                <span className="logtestdiv"></span>
              </div> */}
              <div className="text-center social-signup-icons">
        

                <div className="Telegramdiv">
                  <a
                    href="https://linktr.ee/2fapva"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa fa-telegram"></i>
                  </a>
                </div>
              </div>
            </>
          </div>
        </Modal>
      ) : (
        <Spinner animation="border" role="status" className="loader"></Spinner>
      )}
    </div>
  );
};

export default Login;
