import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { orderListCall, cancelCall, finishCall } from "../../calls";
import "./orderList.css";
import Error from "../../components/Error/Error";
import Spinner from "react-bootstrap/Spinner";

import check from "../../images/check.png";
import close from "../../images/close.png";
import expired from "../../images/expired.png";
import block10 from "../../images/block.png";
import { useTranslation } from "react-i18next";
////
const OrderList = (props) => {
  const {
    setIsLoggedIn,
    isLoggedIn,
    setBalance,
    showErrorModal,
    setShowErrorModal,
    errorMsg,
    setErrorMsg,
    loader,
    setLoader,
  } = props;

  const data = {
    balance: 18,
    orders: [],
  };

  const showError = () => {
    return (
      <div className="row">
        <Error
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          errorMsg={errorMsg}
        ></Error>
      </div>
    );
  };

  const [orderListData, setOrderListData] = useState(data);

  const executeCall = (id, callback) => {
    setLoader(false);
    // console.log("execute", id, callback);
    callback(id)
      .then((response) => {
        setLoader(true);
        if (response.data.balance) {
          setBalance(response.data.balance);
        }
        listOrders();
      })
      .catch((err) => {
        setLoader(true);
        console.log(err);
        if (err.response && err.response.status === 401) {
          setIsLoggedIn(false);
          localStorage.removeItem("token");
        }
        setShowErrorModal(true);
        setErrorMsg([err.response.data.message]);
      });
  };

  const listOrders = () => {
    setLoader(false);
    orderListCall()
      .then((response) => {
        setLoader(true);
        setOrderListData(response.data);
        setBalance(response.data.balance);
      })
      .catch((err) => {
        console.log(err);
        setLoader(true);
        if (err.response && err.response.status === 401) {
          setIsLoggedIn(false);
          localStorage.removeItem("token");
        }
        setShowErrorModal(true);
        setErrorMsg([err.message]);
      });
  };

  const orderListFunc = orderListData.orders.map((item, index) => {
    const iconMap = {
      "sms pending": close,
      success: check,
      cancelled: block10,
      finished: check,
      expired: expired,
    };

    const date = new Date(item.created_at);

    const onClick = item.status === "sms pending" ? cancelCall : finishCall;

    const isClickAble =
      item.status === "sms pending" || item.status === "success";

    return (
      <tr key={index} className="locTR">
        <th scope="row">{item.id}</th>
        <td>{item.country}</td>
        <td>{item.service}</td>
        <td>{item.number}</td>
        <td>{date.toDateString()}</td>
        <td className="code">
          {item.sms_codes.length || item.status !== "sms pending" ? (
            item.sms_codes.toString()
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/loading.gif`}
              className="logoSize"
              alt="loader..."
              rel="preload"
            />
          )}
        </td>
        <td className="listEnd">
          <img
            onClick={isClickAble ? () => executeCall(item.id, onClick) : ""}
            className={`${isClickAble ? "click" : "click2"} `}
            src={iconMap[item.status]}
            width="25px"
            height="25px"
            alt="notification-icon"
            rel="preload"
          />
        </td>
      </tr>
    );
  });
  const { t } = useTranslation();

  useEffect(() => {
    listOrders();
    const intervalId = setInterval(() => {
      listOrders();
    }, 1500000000);

    return () => clearInterval(intervalId);
  }, [setIsLoggedIn, setErrorMsg, setShowErrorModal]);

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else
    return (
      <div className="container-lg">
        {showError()}
        <div className="row ">
          <div className="col-12 table-card same-space-bottom table-min-height">
            {loader ? (
              <>
                <div className="row">
                  <h1 className="text-align-center title1">{`${t('overview.order-history')}`}</h1>
                  <h6 className="text-align-center margin-bottom-10">
                    {orderListData.orders.length}{`${t('overview.entries-total')}`}
                  </h6>
                </div>
                <div className="row">
                  <div className="orderListTable">
                    <table className="table table-strip col-5">
                      <thead>
                        <tr>
                          <td scope="col">{`${t('overview.id')}`}</td>
                          <td scope="col">{`${t('overview.country')}`}</td>
                          <td scope="col">{`${t('overview.service')}`}</td>
                          <td scope="col">{`${t('overview.number')}`}</td>
                          <td scope="col">{`${t('overview.date')}`}</td>
                          <td scope="col">{`${t('overview.sms')}`}</td>
                          <td scope="col">{`${t('overview.status')}`}</td>
                        </tr>
                      </thead>
                      <tbody>{orderListFunc}</tbody>
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <Spinner
                animation="border"
                role="status"
                className="loader"
              ></Spinner>
            )}
          </div>
        </div>
      </div>
    );
};

export default OrderList;
