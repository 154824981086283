import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  balanceHistoryCall,
  changePassCall,
  forgerPassCall,
  keyCall,
  resetPassCall,
} from "../../calls";
import ClipLoader from "react-spinners/ClipLoader";
import Error from "../../components/Error/Error";
import Spinner from "react-bootstrap/Spinner";
import Login from "../../components/login/login";
import Signup from "../../components/signup/signup";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetPassword = (props) => {
  const {
    setShowModal,
    setShowModal2,
    setIsLoggedIn,
    showModal,
    showModal2,
    isLoggedIn,
    setBalance,
    showErrorModal,
    setShowErrorModal,
    errorMsg,
    setErrorMsg,
    loader,
    setLoader,
  } = props;

  const [pass, setPass] = useState({
    password: "",
    confirmPass: "",
  });

  const [token, setToken] = useState("");

  const [error, setError] = useState([]);

  const [success, setSuccess] = useState("");

  const search = useLocation().search;
  const param = new URLSearchParams(search).get("token");

  console.log("param: ", param);

  const showError = () => {
    return (
      <div className="row">
        <Error
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          errorMsg={errorMsg}
        ></Error>
      </div>
    );
  };

  const handleDataChange = (e) => {
    setPass({
      ...pass,
      [e.target.name]: e.target.value,
    });
  };

  const { tokens, userid } = useParams();
  console.log(tokens);

  const handleSubmit = (e) => {
    e.preventDefault();
    setToken(tokens);
    console.log("token: ", token);
    if (pass.password === pass.confirmPass) {
      setLoader(false);
      setError([]);
      resetPassCall(pass, tokens, userid)
        .then((response) => {
          setLoader(true);
          const { data } = response;
          setSuccess("Password Successfully Changed ");
          setPass({
            ...pass,
          });
        })
        .catch((err) => {
          setLoader(true);
          if (err.response && err.response.status === 404) {
            setErrorMsg(["Invalid Token"]);
            setShowErrorModal(true);
          }
        });
    } else {
      console.log("Does not match");
      setError(["Password does not match!"]);
    }
  };
  const { t } = useTranslation();

  if (!isLoggedIn) {
    return (
      <div className="container-lg forgetpasswords table-min-heightforget">
        {!loader ? (
          <Spinner
            animation="border"
            role="status"
            className="loader"
          ></Spinner>
        ) : (
          <></>
        )}

        <div className="forgetpasswordsform">
          <div className="row margin-top-10">
            <div className="row margin-top-10 text-align-start font-size-small padding-top-10">
              <h1 className="title2Forget mb-6">{`${t(
                "overview.reset-pass"
              )}`}</h1>
            </div>
            <div className="form_sec">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label for="old_password" className="form-label">
                    {`${t("overview.new-pass")}`}
                  </label>
                  <input
                    required
                    value={pass.password}
                    onChange={handleDataChange}
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                  />
                </div>
                <div className="mb-3">
                  <label for="old_password" className="form-label">
                    {`${t("overview.confirm-pass")}`}
                  </label>
                  <input
                    required
                    value={pass.confirmPass}
                    onChange={handleDataChange}
                    type="password"
                    className="form-control"
                    id="confirmPass"
                    name="confirmPass"
                  />
                </div>
                <div className="row">
                  {error}
                  <h5 className="success_msg">{success}</h5>
                </div>
                <div className="text-align-end">
                  <button type="submit" className="btn Forgetbtn">
                    {`${t("overview.reset-pass")}`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {showError()}
      </div>
    );
  } else {
    return <Navigate replace to="/" />;
  }
};

export default ResetPassword;
