import axios from "axios";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../components/login/login.css";
import "./../images/main.png";
import groupImage from "./../images/Group2.svg";
import profile from "./../images/profile.svg";
import logo from "./../images/2FAPVA_logo-removebg-preview.svg";
import telegram from "./../images/telegram.png";
import LanguagesButton from "../components/languageButton/LanguagesButton.jsx";

const BASE_URL = "https://production2fapva.herokuapp.com";

const NavBar = (props) => {
  const { t } = useTranslation();
  const { isLoggedIn, setShowModal, setIsLoggedIn, balance } = props;
  const user_id = localStorage.getItem("id");
  const logout = () => {
    axios({
      method: "get",
      url: `${BASE_URL}/user/signout`,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    window.location.reload(false);
  };

  return (
    <nav className="navbar navbar-expand-xl new_navbar">
      <div className="container-xxl">
        <div className="row ml-0">
          <NavLink className="logo active" to={`/`}>
            <img
              alt="2FAPVA"
              className="newLogo"
              src={logo}
              width="200px"
              height="72px"
              rel="preload"
              as="image"
            />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          {isLoggedIn && (
            <div className="balance d-xl-none d-block">
              {/* <span className="">${Math.round(balance * 100) / 100 }</span> */}
              <NavLink
                className="balanceNew position-static d-flex align-items-center"
                to={`/deposit`}
              >
                <div className="dolsCSS position-static pe-1">$</div>{" "}
                <div className="balance_amount">
                  {Math.round(balance * 100) / 100}
                </div>{" "}
              </NavLink>
            </div>
          )}
          {/* <a
            href="https://linktr.ee/2fapva"
            target="_blank"
            rel="noreferrer"
            className="d-xl-none"
          >
            <img alt="" className="telegram mx-sm-3 mx-1" src={telegram} />
          </a> */}

          <div className="d-xl-none d-block m-1 m-sm-3">
            <LanguagesButton />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className="collapse navbar-collapse navExtended"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
         
            
            {isLoggedIn && (
              <li className="nav-item">
                <NavLink className="narBarBtn narBarBtn-new" to={`/order-list`}>
                  {`${t("overview.order-list")}`}
                </NavLink>
              </li>
            )}
            {isLoggedIn && (
              <li className="nav-item">
                <NavLink className="narBarBtn narBarBtn-new" to={`/deposit`}>
                  {`${t("overview.addbalance")}`}{" "}
                </NavLink>
              </li>
            )}
 {
              <li className="nav-item">
                <NavLink className="narBarBtn narBarBtn-new" to={`/help`}>
                  {`${t("overview.faq-1")}`}
                </NavLink>
              </li>
            }

            <li className="nav-item">
              <a
                href="https://documenter.getpostman.com/view/22346587/2s8Z6vZa3m#introduction"
                target="_blank"
                rel="noreferrer"
                className="narBarBtn narBarBtn-new"
              >
                {`${t("overview.api-1")}`}
              </a>
            </li>

            <li className="nav-item">
              <NavLink className="narBarBtn narBarBtn-new" to={`/blog`}>
                {`${t("overview.blog-1")}`}
              </NavLink>
              {/* <a href={`/blog`} className="narBarBtn narBarBtn-new">
                {`${t("overview.blog-1")}`}
              </a> */}
            </li>
          </ul>

          <div className="d-flex justify-content-center">
            <a
              href="https://telegram.me/help_2fapva"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              <img alt="" className="telegram mx-xl-3 ms-auto" src={telegram} />
            </a>
          </div>
          {/* <a
            href="https://linktr.ee/2fapva"
            target="_blank"
            rel="noreferrer"
            className="d-none d-xl-inline-block"
          >
            <img alt="" className="telegram mx-3" src={telegram} />
          </a> */}

          {isLoggedIn && (
            <div className="balance balance-adjust d-none d-xl-block">
              <NavLink
                className="balanceNew position-static d-flex align-items-center"
                to={`/deposit`}
              >
                <div className="dolsCSS position-static pe-1">$</div>{" "}
                <div className="balance_amount">
                  {Math.round(balance * 100) / 100}
                </div>{" "}
              </NavLink>
            </div>
          )}

          <div className="d-none d-xl-block m-3">
            <LanguagesButton />
          </div>

          {!isLoggedIn && (
            <div className="btn d-flex" onClick={() => setShowModal(true)}>
              <img alt="" src={groupImage}></img>
            </div>
          )}

          {isLoggedIn && (
            <ul className="nav navbar-nav">
              <li className="nav-item1 dropdown">
                <img
                  alt=""
                  src={profile}
                  className="btn dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                />
                <span className="visually-hidden">Toggle Dropdown</span>
                <div className="dropdown-menu dropdown-menu-end dropdown-new-class">
                <span
                    className="btn dropdown-item  same-dropdown"
                  >
                    User ID : {user_id}
                  </span>
                  <NavLink
                    className="locSet"
                    to={`/change-password`}
                    style={{ fontSize: "19px" }}
                  >
                    {`${t("overview.settings")}`}
                  </NavLink>

                  <div className="dropdown-divider"></div>
                  <span
                    className="btn dropdown-item  same-dropdown"
                    onClick={logout}
                  >
                    {`${t("overview.log-out")}`}
                  </span>
         
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
    // <nav className="navbar navbar-expand-lg  new_navbar">
    //     <div className="row">
    //       <div className='col-1'></div>
    //       <div className="col-3">
    //         <NavLink className="logo" to="/">SIM SWITCH</NavLink>
    //         <h6 className="messaging_service">Messaging Service</h6>
    //       </div>
    //       <div className="col-1">
    //         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    //           <span className="navbar-toggler-icon"></span>
    //         </button>
    //       </div>
    //       <div className="col-7">
    //         <div className="collapse navbar-collapse" id="navbarNav">
    //           { isLoggedIn && <div className="col-4">
    //             <NavLink className="btn text-black narBarBtn3" to="/">Sim Switch</NavLink>
    //             <NavLink className="btn text-black narBarBtn" to="/balance-history">Balance History</NavLink>
    //             <NavLink className="btn text-black narBarBtn2" to="/order-list">Order List</NavLink>
    //           </div>}
    //           { isLoggedIn && (
    //             <div className="nav-item text-black balance">
    //               <span className="margin-top">${Math.round(balance * 100) / 100 }</span>
    //             </div>
    //           )}
    //           { !isLoggedIn && (
    //               <div className="col-1 text-align-end" onClick={() => setShowModal(true)}>
    //                 <img src={groupImage}></img>
    //               </div>
    //             )}
    //           <ul className="navbar-nav">
    //             { isLoggedIn && (
    //               <ul className="nav navbar-nav ms-auto">
    //                 <li className="nav-item dropdown">
    //                 <img src={profile}  className="btn dropdown-toggle dropdown-toggle-split margin-right-45" data-bs-toggle="dropdown"/>
    //                     <span className="visually-hidden">Toggle Dropdown</span>
    //                     <div className="dropdown-menu">
    //                         <NavLink className="btn dropdown" to="/change-password">Settings</NavLink>
    //                         <div className="dropdown-divider"></div>
    //                         <span className="btn dropdown-item" href="#" onClick={logout}>Log Out</span>
    //                     </div>
    //                 </li>
    //               </ul>
    //             )}
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </nav>
  );
};

export default NavBar;
