/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdFileCopy } from "react-icons/md";
import { useTranslation } from "react-i18next";

import Payment from "../../components/payment/Payment";
import {
  depositCallCrypto,
  depositCallFiat,
  balanceHistoryCall,
} from "../../calls";
import ClipLoader from "react-spinners/ClipLoader";
import Error from "../../components/Error/Error";
import logo from "./../../images/logoImg.svg";
import "./deposit.css";
import { findRenderedDOMComponentWithTag } from "react-dom/test-utils";

const visa_img = require("../../images/fiatImages/visa.png");
const alipay_img = require("../../images/fiatImages/alipay.png");
const capitalist_img = require("../../images/fiatImages/capitalist.png");
const perfect_money_img = require("../../images/fiatImages/perfect-money.png");

const ethereum_img = require("../../images/cryptoImages/ethereum.png");
const bitcoin_img = require("../../images/cryptoImages/bitcoin.png");
const monero_img = require("../../images/cryptoImages/monero.png");
const ripple_img = require("../../images/cryptoImages/ripple.png");
const bnb_img = require("../../images/cryptoImages/bnb.png");
const ltc_img = require("../../images/cryptoImages/LTC.png");
const doge_img = require("../../images/cryptoImages/doge.png");
const dash_img = require("../../images/cryptoImages/dash.png");
const trx_img = require("../../images/cryptoImages/trx.png");
const usdt_img = require("../../images/cryptoImages/USDTTRC20.png");

const Deposit = (props) => {
  const { setIsLoggedIn, isLoggedIn, loader, setLoader } = props;

  const { t } = useTranslation();

  const data = {
    amount: "",
    method_payment: "cryptocurrency",
  };
  const data1 = [
    {
      created_at: "",
      amount: 0.0,
    },
  ];

  const cryptoArray = [
    {
      id: 18,
      img: usdt_img,
      value: "USDTTRC20",
    },
    {
      id: 6,
      img: bitcoin_img,
      value: "BTC",
    },
    {
      id: 8,
      img: ethereum_img,
      value: "ETH",
    },
    {
      id: 9,
      img: monero_img,
      value: "XMR",
    },

    {
      id: 14,
      img: bnb_img,
      value: "BNB",
    },
    {
      id: 15,
      img: ltc_img,
      value: "LTC",
    },
    {
      id: 13,
      img: doge_img,
      value: "DOGE",
    },
    {
      id: 12,
      img: dash_img,
      value: "DASH",
    },
    {
      id: 20,
      img: trx_img,
      value: "TRX",
    },
 
  ];

  const [amount, setAmount] = useState(data.amount);
  const [network,setNetwork] = useState("null");
  const [crypto_amount, setCryptoAmount] = useState(0);
  const [method_payment, setMethodPayment] = useState(data.method_payment);
  const [error, setError] = useState([]);
  const [errorS, setErrorS] = useState(false);
  const [success, setSuccess] = useState("");
  const [show, setShow] = useState(false);
  const [address, setAddress] = useState("null");
  const [isCopied, setCopied] = useState(false);
  const [isAmountCopied, setAmountCopied] = useState(false);

  const [isFiat, setFiat] = useState(false);
  const [isCrypto, setCrypto] = useState(true);

  const [historyData, setHistoryData] = useState(data1);

  const [idDataGet, setIdDataGet] = useState();
  const [fiatPaymentsDeposit, setFiatPaymentsDeposit] = useState(cryptoArray);
  const Bruno = fiatPaymentsDeposit.find((item) => item.id === idDataGet);

  useEffect(() => {
    let btn_fiat = document.querySelector(".btn-fiat");
    btn_fiat.addEventListener("click", () => {
      setFiat(true);
      setCrypto(false);
    });
  }, []);

  useEffect(() => {
    let btn_crypto = document.querySelector(".btn-crypto");
    btn_crypto.addEventListener("click", () => {
      setCrypto(true);
      setFiat(false);
    });
  }, []);

  useEffect(() => {
    let payments = document.getElementsByClassName("card-payment");
    let methods_payments = Array.from(payments);
    methods_payments.forEach((el) => {
      el.classList.remove("payment-option-selected");
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let payments = document.getElementsByClassName("card-payment");
      let methods_payments = Array.from(payments);
      methods_payments.forEach((el) => {
        el.addEventListener("click", () => {
          setMethodPayment(el.id);
        });
      });
    }, 100);
  }, [isFiat, isCrypto]);

  useEffect(() => {
    setLoader(false);
    balanceHistoryCall()
      .then((response) => {
        setLoader(true);
        setHistoryData(response.data);
      })
      .catch((err) => {
        setLoader(true);
        console.log("HIstory Error: ", err);
        if (err.response && err.response.status === 401) {
          setIsLoggedIn(false);
          localStorage.removeItem("token");
        }
      });
  }, [setIsLoggedIn]);

  const historyList = historyData.map((item, index) => {
    const date = new Date(item.created_at);
    console.log("Amount: ", localStorage.getItem("token"));
    return (
      <tr key={index} className="listL">
        <th scope="row">{index + 1}</th>
        <td>{item.amount} $</td>
        <td>{date.toDateString()}</td>
        <td className="lastEl">{date.toTimeString()}</td>
      </tr>
    );
  });

  const errorMsg = error.map((item, index) => {
    return (
      <h5 key={index} className="error_msg">
        {item}
      </h5>
    );
  });

  const handleDataChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setAmount(result);
    console.log(amount);
  };

  const handleClose = () => {
    setShow(false);
    setCopied(false);
    setAmountCopied(false);
    setAddress("null");
    
  };
  const handleShow = () => setShow(true);

  const handleUrlChange = (value) => {
    setAddress(value);
    setCopied(false);
  };


  const renderTag = () => {
    return `${t("overview.amount-BTC")}`
  }

  const displayQR = () => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header  closeButton>
          <Modal.Title className="main-modal">
            <h2 className="titledepositpage">
              {" "}
              {/* {method_payment} */}
              {`${t("overview.payment-crypto")}`} :
              <img
                className="payment-img"
                src={Bruno?.img}
                alt="payment_icon"
                height="50px"
                rel="preload"
              />
            </h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-3 container">
            <div className="formsec1">
              <div className="QRCode">
                <div
                  className="mb-2 "
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 100,
                    width: "100%",
                  }}
                >
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={address}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
              <form className="formpaym">
                {isCopied ? (
                  <div id="success" class="form-text text-success">
                    {" "}
                    {`${t("overview.add-copied")}`}
                  </div>
                ) : null}
                {isAmountCopied ? (
                  <div id="success" class="form-text text-success">
                    {" "}
                    {`${t("overview.amount-copied")}`}
                  </div>
                ) : null}
                <div className="mb-3 popup-address">
                
                  <label className="form-label popup-label address-label">
                    {`${t("overview.transfer-am")}`}
                  </label>
                 
                  
                  <div className="input-group popup-group">
                    
                    <input
                      id="crypto-url"
                      
                      type="text"
                      className=" font-address form-control adress-input input-address"
                      value={address}
                      onChange={({ target: { value } }) =>
                        handleUrlChange(value)
                      }
                      readOnly={true}
                    />
                    <CopyToClipboard
                      text={address}
                      onCopy={() => setCopied(true)}
                    >
                      <span
                        id="copy-icon"
                        style={{ cursor: "pointer" }}
                        className="input-group-text"
                      >
                        <MdFileCopy />
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>

                <div className="mb-3 popup-input small-imput">
                  <label className="form-label popup-label amount-btc">
                    {}
                    { Bruno.value == "BTC" && `${t("overview.amount-BTC")}`}
                    { Bruno.value == "XMR" && `${t("overview.amount-XMR")}`}
                    { Bruno.value == "BNB" && `${t("overview.amount-BNB")}`}
                    { Bruno.value == "LTC" && `${t("overview.amount-LTC")}`}
                    { Bruno.value == "DOGE" && `${t("overview.amount-DOGE")}`}
                    { Bruno.value == "DASH" && `${t("overview.amount-DASH")}`}
                    { Bruno.value == "TRX" && `${t("overview.amount-TRX")}`}
                    { Bruno.value == "USDTTRC20" && `${t("overview.amount-USDTTRC20")}`}
                    { Bruno.value == "ETH" && `${t("overview.amount-ETH")}`}

                  </label>
                  <div className="input-group popup-group">
                    <input
                      type="number"
                      className="form-control amount-input"
                      value={crypto_amount}
                      readOnly
                    />
                    <CopyToClipboard
                      text={crypto_amount}
                      onCopy={() => setAmountCopied(true)}
                    >
                      <span
                        id="copy-icon"
                        style={{ cursor: "pointer"}}
                        className="input-group-text"
                      >
                        <MdFileCopy />
                      </span>
                    </CopyToClipboard>
                    {/* <span className="input-group-text" 
                    id="basic-addon2"
                    >
                      BTC
                    </span> */}
                  </div>
                </div>
                <div className="mb-3 popup-input small-imput">
                  <label className="form-label popup-label amount-btc">
                    {`${t("overview.payment-cryptodes")}`}
                  </label>
                  <div className="input-group popup-group">
                    <input
                      type="number"
                      className="form-control amount-usd"
                      value={amount}
                      readOnly
                    />
                    {/* <span className="input-group-text" id="basic-addon2">{`${t(
                      "overview.usd"
                    )}`}</span> */}
                  </div>
                </div>
                <div className="mb-3 popup-input small-imput">
                  <label className="form-label popup-label amount-btc">
                    Network
                  </label>
                  <div className="input-group popup-group">
                    <input
                      type="text"
                      className="form-control amount-usd"
                      value={network}
                      readOnly
                    />
                    {/* <span className="input-group-text" id="basic-addon2">{`${t(
                      "overview.usd"
                    )}`}</span> */}
                  </div>
                  </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleSubmit = (e) => {
    console.log("Amount", amount);
    console.log("Method Payment", method_payment);
    setLoader(false);
    setError([]);
    e.preventDefault();
    console.log(isCrypto, isFiat);
    
    if (amount < 2) {  // Vérifier si le montant est supérieur à 3
      setError(["Amount should be greater than 1"]); // Afficher un message d'erreur
      setErrorS(true);
      setLoader(true)
      return;
    }
    
    if (!method_payment) {
      setLoader(true);
      console.log("method payment required");
    } else {
      if (isCrypto) {
        depositCallCrypto(amount, method_payment)
        .then((response) => {
          setLoader(true);
          const url = response.data["link_page_url"];
          window.location.href = `${url}`;
        })
        .catch((err) => {
          setLoader(true);
          setError([err.response.data.message]);
          setErrorS(true);
          console.log("ERROR: ", err.response.data);
        });
      } else if (isFiat) {
        depositCallFiat(amount, method_payment)
          .then((response) => {
            setLoader(true);
            const url = response.data["link_page_url"];
            window.location.href = `${url}`;
          })
          .catch((err) => {
            setLoader(true);
            setError([err.response.data.message]);
            setErrorS(true);
            console.log("ERROR: ", err.response.data);
          });
      }
    }
};

  if (!isLoggedIn) {
    console.log("navigating");
    return <Navigate replace to="/" />;
  } else
    return (
      <>
        <Payment
          setIdDataGet={setIdDataGet}
          amountSection={[
            <>
              <div className="show row margin-top-10">
                {!loader ? (
                  <Spinner
                    animation="border"
                    role="status"
                    className="loader"
                  ></Spinner>
                ) : (
                  <></>
                )}
                <div className="row margin-top-10 data-row buy-btn text-align-start margin-bottom-10">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3 d-flex justify-content-center">
                      {/* <label for="confirm_password" className="form-label">Confirm Password</label>/ */}
                      <input
                        required
                        value={amount}
                        onChange={handleDataChange}
                        min="0"
                        className={
                          errorS ? ` backG  amountG ` : ` backG amountG `
                        }
                        id="confirm_password"
                        name="confirm_password"
                        placeholder="Enter Amount"
                      />
                    </div>
                    <div className="row">{errorMsg}</div>
                    <div className="row">
                      <h5 className="success_msg">{success}</h5>
                    </div>
                    <div className="col-5 Proceedbtn">
                      <button
                        type="submit"
                        className="btn btn-primary btnColorChangeDeposit"
                        onClick={handleSubmit}
                      >
                        {`${t("overview.proceed")}`}
                      </button>
                    </div>
                  </form>
                  <div className="container-lg">
                    <div className="row ">
                      {loader ? (
                        <div className="col-12 table-card">
                          <div className="row">
                            <h2
                              className="text-align-center bal title1"
                              style={{ fontSize: "40px" }}
                            >
                              {`${t("overview.balance-history")}`}
                            </h2>
                            <h6 className="text-align-center margin-bottom-10 entries">
                              {historyData.length}{" "}
                              {`${t("overview.entries-total")}`}
                            </h6>
                          </div>
                          <div className="row">
                            <table className="table table-strip col-5">
                              <thead>
                                <tr nameClass="head">
                                  <td scope="col">{`${t("overview.id")}`}</td>
                                  <td scope="col">{`${t(
                                    "overview.amount"
                                  )}`}</td>
                                  <td scope="col">{`${t("overview.date")}`}</td>
                                  <td scope="col">{`${t("overview.time")}`}</td>
                                </tr>
                              </thead>
                              <tbody>{historyList}</tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <Spinner
                          animation="border"
                          role="status"
                          className="loader"
                        ></Spinner>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {show ? displayQR() : null}
            </>,
          ]}
        />
      </>
    );
};

export default Deposit;
