import React from "react";
import { useTranslation } from "react-i18next";

import "./Footer-pages.css";
import { PaymentPolicydata } from "../../Container/2FA-Alldata";
import { Helmet } from "react-helmet";

const Payment_policy = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white">
      <Helmet>
       <meta name="title" content="Payment Policy | 2FAPVA" />
        <meta name="description" content="Discover our Payment Policy and the various options available for securely making transactions on our website. Learn about our refund and cancellation policies, and how we protect your financial information." />
        <meta name="keywords" content="virtual phone number payment policy, virtual phone number billing policy, virtual phone number payment terms, virtual phone number payment options, virtual phone number payment methods, virtual phone number payment plans, virtual phone number subscription plans, virtual phone number pricing policy, virtual phone number payment gateway, virtual phone number payment processing, virtual phone number payment system, virtual phone number billing system" />
      </Helmet>
      <div className="container-lg  table-card table-min-height same-space-bottom-">
        <h3 className="text-center title2">{`${t('payment-policy-data.main_title')}`}</h3>

        <div className="privacypage">
          <p className="desone">{`${t('payment-policy-data.desone')}`}</p>
          <h2 className="tilte-privacy">{`${t('payment-policy-data.main_title')}`}</h2>
          <p className="desone">{`${t('payment-policy-data.destwo')}`}</p>
          <p className="desone-des1">{`${t('payment-policy-data.desthree')}`}</p>
          <h2 className="tilte-privacy">{`${t('payment-policy-data.contact_us')}`}</h2>
          <p className="desone">{`${t('payment-policy-data.desfour')}`}</p>
          <p className="desone">{`${t('payment-policy-data.desfive')}`}</p>
        </div>
        {/* <div className="privacy-policy-main footerpages-height">
        <div className="footerall-content">
          <h4>Comming Soon</h4>
        </div>
      </div> */}
        </div>
      </div>
  
  );
};

export default Payment_policy;
