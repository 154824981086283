import i18n from 'i18next';
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';


i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        preload: ['en'],
        load: 'languageOnly',
        fallbackLng:'en',
        debug: true,
        lowerCaseLng: true,
        detection: {
            order: ['path', 'localStorage']
        }
    });

export default i18n;