import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";

import './styles.css';


const cookie_img = require('../../images/cookie.png');


const CookiesModal = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);
  const [showAccept, setShowAccept] = useState(false);
  const [manageCookies, setManageCookies] = useState(false);
  const [sessionChecked, setSessionChecked] = useState(true);
  const [analyticsChecked, setAnalyticsChecked] = useState(true);
  const [targetChecked, setTargetChecked] = useState(true);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleClose = () => setIsOpen(false);

  const handleManageCookies = () => {
    setManageCookies(true);
    setShowAccept(true);
  }
  
  const handleSessionClicked = () => {
    setSessionChecked(!sessionChecked);
  }

  const handleAnalyticsClicked = () => {
    setAnalyticsChecked(!analyticsChecked);
  }
  
  const handleTargetClicked = () => {
    setTargetChecked(!targetChecked);
  }
  
  
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const handleAcceptClicked = () => {
    sessionChecked && Cookies.set('session_id', 'XXXXX');
    analyticsChecked && Cookies.set('analytics_id', 'XXXXX', { expires: 365 });
    targetChecked && Cookies.set('targeting_id', 'XXXXXX', { expires: 365 });
    handleClose();
  }
  
  const handleAcceptAll = () => {
    Cookies.set('session_id', 'XXXXX');
    Cookies.set('analytics_id', 'XXXXX', { expires: 365 });
    Cookies.set('targeting_id', 'XXXXXX', { expires: 365 });
    handleClose();
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);

  }, []);

  useEffect(() => {
    let sessionCookies = Cookies.get('session_id');
    let analyticsCookies = Cookies.get('analytics_id');
    let targetCookies = Cookies.get('targeting_id');
    let anyCookies = analyticsCookies || targetCookies || sessionCookies;

    if (anyCookies) {
      setIsOpen(false);
    }

    console.log('session_id :', sessionCookies);
    console.log('analytics_id', analyticsCookies);
    console.log('targeting_id', targetCookies);
  }, []);
  

  return (
    <div className={`${isOpen ? 'd-block' : 'd-none'} ${dimensions.width <= 768 ? 'cookies-center' : 'cookies'}`}>
      <div className="container">
        <div className="mb-4 cookies-header d-flex justify-content-between align-items-center">
          <div className='d-flex align-items-center'>
            <h4 className='m-0 me-2'><strong>{`${t('overview.cookies-title')}`}</strong></h4>
            <img style={{ width: '40px', height: '40px' }} alt='cookie logo' src={cookie_img} />
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="btn-close closButton me-0"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="cookies-body">
          <div className={`mb-3 cookies-body-text  ${manageCookies ? 'd-none' : 'd-block'}`}>
            <p>{`${t('overview.cookies-des')}`}</p>
          </div>
          <div className={`mb-3 cookies-body-checks  ${manageCookies ? 'd-block' : 'd-none'}`}>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="session" checked={sessionChecked} onClick={handleSessionClicked} />
              <label className="form-check-label">
                {`${t('overview.sess-tracking')}`}
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="analytics" checked={analyticsChecked} onClick={handleAnalyticsClicked} />
              <label className="form-check-label">
                {`${t('overview.analtics-tracking')}`}
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="targeting" checked={targetChecked} onClick={handleTargetClicked} />
              <label className="form-check-label">
                {`${t('overview.targeting')}`}
              </label>
            </div>
          </div>
        </div>
        <div className="cookies-footer d-flex">
          <button className={`btn outline-primary me-3 ${showAccept ? 'd-none' : 'd-block'}`} onClick={handleManageCookies}>
            {`${t('overview.manage-cookies')}`}
          </button>
          <button className={`btn primary ${showAccept ? 'd-none' : 'd-block'}`} onClick={handleAcceptAll}>
          {`${t('overview.accept-all')}`}
          </button>
          <button className={`btn primary ${showAccept ? 'd-block' : 'd-none'}`} onClick={handleAcceptClicked}>
          {`${t('overview.confirm-selection')}`}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookiesModal;