import React, { useEffect, useState } from 'react';
import { getFreeNumbers } from "../../calls";
import { Helmet } from 'react-helmet';
import './freenum.css'
import usaflag from "./../../images/USA.jpg";
import messageIcn2 from "./../../images/message-icon-2.png";
import { useTranslation } from "react-i18next";

const Freenum = (props) => {
  const {setLoader} = props; 
  const [data, setData] = useState({});
  const [currentNumber, setcurrentNumber] = useState([]);
  const [currentNumberIndex, setcurrentNumberIndex] = useState(null);
  const ListFreeNumbers = () =>{
    setLoader(false);
    getFreeNumbers()
    
    .then((response) => {
      setLoader(true);
        // setLoader(false);
    

      setData(response.data)
      updateIndex(0);

    })

  };
  useEffect(() => {
    ListFreeNumbers();
  },[]);

  const updateIndex = (index) => {
    console.log(index);
    if(data.phones && data.phones.length > 0) {
      setcurrentNumber(data.phones[index]);
      setcurrentNumberIndex(index);
    }
  }
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{`${t('overview.freenumtitle')}`}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content="Get free virtual phone numbers for SMS verification phillipines, russia, usa, bulgarie, turkey, ukraine, australia and +30 countries. Use our free virtual mobile numbers to receive sms online and verify your accounts for free.Bypass SMS verification with 2FAPVA." />
        <meta name="keywords" content="free virtual mobile number for sms verification, free virtual mobile number for sms verification philippines, free usa virtual mobile number for sms verification, free australia virtual mobile number for sms verification, free virtual mobile number for sms verification netherlands, free virtual mobile number for sms verification google voice, free virtual mobile number for sms verification usa, free virtual mobile number for sms verification microsoft, free virtual mobile number for sms verification thailand, free virtual mobile number for sms verification app, free virtual mobile number for sms verification canada, free virtual mobile number for sms verification australia, virtual mobile number for sms verification free,free number for sms verification,sms verification,sms verification online free,sms verification for free online,two factor authentification free,two factor authentification online,verify my account for free,bypass sms verification with free number,free virtual phone number,Virtual Freephone Numbers,Best FREE Virtual Phone Number For Your Business,Free Virtual Number,Free Virtual Phone Number,get a free virtual number,free virtual number Nike,free virtual number Dream11,free virtual number Telegram,free virtual number Whatsapp,get a free phone number,
get free WhatsApp virtual number,get free facebook virtual number,get free amazon virtual number, get free tiktok virtual number, get free gmail virtual number, get free Twitter virtual number, free Instagram virtual number, free LinkedIn virtual number, free Snapchat virtual number, free Pinterest virtual number, free PayPal virtual number, free Coinbase virtual number, free GitHub virtual number, free virtual number VKontakte, free virtual number WeChat, free virtual number Telegram, free virtual number OK, free virtual number Viber, free virtual number Uber, free virtual number Gett, free virtual number OLX, free virtual number Mail.ru, free virtual number Microsoft, free virtual number Airbnb, free virtual number Line messenger, free virtual number Yahoo, free virtual number DrugVokrug, free virtual number X5ID, free virtual number Delivery Club, free virtual number Yandex, free virtual number Steam, free virtual number Tinder, free virtual number Mamba, free virtual number KakaoTalk, free virtual number AOL, free virtual number Tencent QQ,
free virtual number Wish, free virtual number Icrypex, free virtual number Baidu, free virtual number Dominos Pizza, free virtual number paycell, free virtual number Lenta, free virtual number Payberry, free virtual number Drom, free virtual number Deliveroo, free virtual number Hotmail, free virtual number PayPal, free virtual number Bumble, free virtual number craigslist, free virtual number Dingtone, free virtual number Ebay, free virtual number Freelancer, free virtual number Gcash, free virtual number Locanto, free virtual number MapleSEA, free virtual number Nike, free virtual number Twilio, free virtual number Venmo, free virtual number Voopee, free virtual number Walmart, free virtual number Yubo, free virtual number Fiverr, free virtual number Tagged, free virtual number Skype, free virtual number Revolut, free virtual number MeetMe, free virtual number Lazada, free virtual number LiveScore, free virtual number LocalBitcoins, free virtual number Adidas, free virtual number lianxin, free virtual number PaxFuL, free virtual number Cabify, free virtual number Okcupid, free virtual number Fastpay, free virtual number paysafecard, free virtual number NETEASE, free virtual number JustDating, free virtual number Tantan, free virtual number New Pay, free virtual number DIDI, free virtual number Potato Chat, free virtual number viva, free virtual number OPENPOINT, free virtual number Nate, free virtual number Blockchain, free virtual number PayZapp, free virtual number GameSword, free virtual number paygo, free virtual number offerup, free virtual number Prepaid2Cash, free virtual number Jollychic, free virtual number MobiKwik, free virtual number Apple, free virtual number Zalo, free virtual number Zoho,
free virtual number Taobao, free virtual number hepsiburada, free virtual number Blizzard, free virtual number HiU, free virtual number 大众点评, free virtual number parity, free virtual number tiger, free virtual number BOTIM, free virtual number 57.ua, free virtual number ACENews, free virtual number gobank, free virtual number MonChats, free virtual number Mercado, free virtual number Cosmos, free virtual number myard, free virtual number HungryPanda, free virtual number moo, free virtual number Hily, free virtual number Lara, free virtual number paywithfour, free virtual number OffGamers, free virtual number Grindr, free virtual number Finances, free virtual number Shein, free virtual number ipair, free virtual number GOMAJ, free virtual number PayTM, free virtual number Dhani, free virtual number CMT Cuzdan, free virtual number CITA PREVIA, free virtual number Trendyol, free virtual number Touchance, free virtual number Hopi, free virtual number Kotak 811, free virtual number Haraj, free virtual number ezbuy, free virtual number pokes, free virtual number Tosla, free virtual number ininal, free virtual number paysend, free virtual number Swvl, free virtual number Snapp Food, free virtual number Algida, free virtual number mzadqatar, free virtual number Gittigidiyor, free virtual number Faberlic, free virtual number cryptocom, free virtual number CafeBazaar, free virtual number Bitaqaty, free virtual number humblebundle, free virtual number Disney Hotstar, free virtual number Alfalah, free virtual number irancell, free virtual number Getir, free virtual number Şikayet var, free virtual number Gamekit,
free virtual number Yaay, free virtual number Dream11, free virtual number Oriflame, free virtual number Bykea, free virtual number Digikala, free virtual number GameArena, free virtual number Bisu, free virtual number egopensooq, free virtual number 1xbet, free virtual number Yemeksepeti, free virtual number Socios, free virtual number Wmaraci, free virtual number myGLO, free virtual number GG, free virtual number Dostavista, free virtual number Airtel, free virtual number MrGreen, free virtual number Rediffmail, free virtual number miloan, free virtual number Monese, free virtual number NCsoft, free virtual number AVON, free virtual number dodopizza, free virtual number McDonalds, free virtual number MyFishka, free virtual number Foody, free virtual number Grab, free virtual number Huya, free virtual number WestStein, free virtual number Tango, free virtual number Global24, free virtual number MVideo, free virtual number Sheerid, free virtual number 99app, free virtual number CAIXA, free virtual number Amasia, free virtual number Immowelt, free virtual number Picpay, free virtual number Blued, free virtual number SpotHit, free virtual number Brand20ua, free virtual number IQOS, free virtual number Banqi, free virtual number Aitu, free virtual number Any other, free virtual number Moneylion, free virtual number Nana, free virtual number B4ucabs, free virtual number ClickEntregas, free virtual number galaxy.win, free virtual number starchat.site,
free virtual number Finn.no, free virtual number BitClout, free virtual number Unacademy, free virtual number Kaidee, free virtual number Quikr, free virtual number Myntra, free virtual number Auto.RU, free virtual number purse.io, free virtual number gem4me.com, free virtual number QROOTO, free virtual number wap.jamango.ru, free virtual number uklon, free virtual number PMSM, free virtual number steemit.com, free virtual number mvideo.ru, free virtual number beepcar, free virtual number prodoctorov.ru, free virtual number chitai-gorod.ru, free virtual number vscale.io, free virtual number mannabase, free virtual number bilshe.mastercard.ua, free virtual number free.sprinthost.ru, free virtual number viberate.com, free virtual number gogotaxi.com.ua, free virtual number advertapp.ru, free virtual number hinge, free virtual number socialtools.ru, free virtual number novilidery.com, free virtual number OKEY, free virtual number exchange.musdcoin.com, free virtual number Azino888, free virtual number sgro, free virtual number hub.iost.io, free virtual number icard.com, free virtual number sms-fly.com, free virtual number Cash Show, free virtual number 英雄互娱, free virtual number 知乎, free virtual number Chivo Wallet, free virtual number uniquecasino, free virtual number 去哪儿网, free virtual number Sahibinden, free virtual number yycom, free virtual number minds.com, free virtual number 速約, free virtual number Movider, free virtual number 糖果口袋, free virtual number Aurora, free virtual number RICHDADDY, free virtual number Minima, free virtual number TNG eWallet, free virtual number 充电, free virtual number MoneyBack, free virtual number Givingli, free virtual number Nico, free virtual number Gratus,
free virtual number Hello BiBi, free virtual number ParPar, free virtual number 拍住赏, free virtual number GODA LIVE, free virtual number 千图, free virtual number BCD, free virtual number Rooit, free virtual number Lizhi FM, free virtual number LianSheng, free virtual number 完美世界, free virtual number HKTVmall, free virtual number GNJOY, free number 5sim, free number 5sim.net, sms man free number, smshub free number, tollfree , voip free number, Где бесплатно получить виртуальный номер?, Сколько стоит виртуальный телефонный номер?, Как создать виртуальный телефонный номер?, Как сделать фальшивый номер телефона?, Купить виртуальный телефонный номер, Виртуальные телефонные номера, Виртуальный номер для приема , Виртуальные номера для приема , Виртуальный телефонный номер, Бесплатный виртуальный номер, принять смс онлайн, Бесплатные виртуальные номера для приема, смс верификация бесплатно, активировать мою учетную запись бесплатно, бесплатный номер для активации учетной записи, 短信验证, 免费短信验证号码, 在线免费短信验证, 在线免费短信验证, 双重认证免费,
在线双重认证, 免费验证我的账户, 使用免费号码绕过短信验证, 免费虚拟电话号码, 免费虚拟免费电话号码, 最好的免费虚拟电话号码, 免费虚拟号, 免费虚拟电话号码, 获取免费虚拟号码, 免费虚拟号码, 免费虚拟号码, 免费虚拟号码 Telegram, 免费虚拟号码 Whatsapp, 获取免费电话号码, 获取免费 WhatsApp 虚拟号码, sms activate free numbers, sms activate free number, sms activate free virtual number, sms activate china, sms activate free number, sms activate free virtual number, free number for sms verification Russia, sms verification Russia, sms verification online free Russia, sms verification for free online Russia, two factor authentification free Russia, two factor authentification online Russia, verify my account for free Russia, bypass sms verification with free number Russia, free virtual phone number Russia, Virtual Freephone Numbers Russia, Best FREE Virtual Phone Number For Your Business Russia, Free Virtual Number Russia, Free Virtual Phone Number Russia, get a free virtual number Russia, free virtual number Nike Russia, free virtual number Dream11 Russia, free virtual number Telegram Russia, free virtual number Whatsapp Russia, get a free phone number Russia, get free WhatsApp virtual number Russia, get free facebook virtual number Russia, get free amazon virtual number Russia, get free tiktok virtual number Russia, get free gmail virtual number Russia, get free Twitter virtual number Russia,
free Instagram virtual number Russia, free LinkedIn virtual number Russia, free Snapchat virtual number Russia, free Pinterest virtual number Russia, free PayPal virtual number Russia, free Coinbase virtual number Russia, free GitHub virtual number Russia, free virtual number VKontakte Russia, free virtual number WeChat Russia, free virtual number Telegram Russia, free virtual number OK Russia, free virtual number Viber Russia, free virtual number Uber Russia, free virtual number Gett Russia, free virtual number OLX Russia, free virtual number Mail.ru Russia, free virtual number Microsoft Russia, free virtual number Airbnb Russia, free virtual number Line messenger Russia, free virtual number Yahoo Russia, free virtual number DrugVokrug Russia, free virtual number X5ID Russia, free virtual number Delivery Club Russia, free virtual number Yandex Russia, free virtual number Steam Russia, free virtual number Tinder Russia, free virtual number Mamba Russia, free virtual number KakaoTalk Russia, free virtual number AOL Russia, free virtual number Tencent QQ Russia, free number for sms verification UK, sms verification UK, sms verification online free UK, sms verification for free online UK, two factor authentification free UK, two factor authentification online UK, verify my account for free UK, bypass sms verification with free number UK, free virtual phone number UK, Virtual Freephone Numbers UK, Best FREE Virtual Phone Number For Your Business UK, Free Virtual Number UK, Free Virtual Phone Number UK, get a free virtual number UK, free virtual number Nike UK, free virtual number Dream11 UK, free virtual number Telegram UK, free virtual number Whatsapp UK, get a free phone number UK, get free WhatsApp virtual number UK, get free facebook virtual number UK, get free amazon virtual number UK, get free tiktok virtual number UK, get free gmail virtual number UK, get free Twitter virtual number UK,
free Instagram virtual number UK, free LinkedIn virtual number UK, free Snapchat virtual number UK, free Pinterest virtual number UK, free PayPal virtual number UK, free Coinbase virtual number UK, free GitHub virtual number UK, free virtual number VKontakte UK, free virtual number WeChat UK, free virtual number Telegram UK, free virtual number OK UK, free virtual number Viber UK, free virtual number Uber UK, free virtual number Gett UK, free virtual number OLX UK,
Best FREE Virtual Phone Number For Your Business USA, Free Virtual Number USA, Free Virtual Phone Number USA, get a free virtual number USA, free virtual number Nike USA, free virtual number Dream11 USA, free virtual number Telegram USA, free virtual number Whatsapp USA, get a free phone number USA, get free WhatsApp virtual number USA, get free facebook virtual number USA, get free amazon virtual number USA, get free tiktok virtual number USA, get free gmail virtual number USA, get free Twitter virtual number USA, free Instagram virtual number USA, free LinkedIn virtual number USA, free Snapchat virtual number USA, free Pinterest virtual number USA, free PayPal virtual number USA, free Coinbase virtual number USA, free GitHub virtual number USA, free virtual number VKontakte USA, free virtual number WeChat USA, free virtual number Telegram USA, free virtual number OK USA, free virtual number Viber USA, free virtual number Uber USA, free virtual number Gett USA, free virtual number OLX USA, free virtual number Mail.ru USA, free virtual number Microsoft USA,
free virtual number Airbnb USA, free virtual number Line messenger USA, free virtual number Yahoo USA, free virtual number DrugVokrug USA, free virtual number X5ID USA, free virtual number Delivery Club USA, free virtual number Yandex USA, free virtual number Steam USA, free virtual number Tinder USA, free virtual number Mamba USA, free virtual number KakaoTalk USA, free virtual number AOL USA, free virtual number Tencent QQ USA, free number for sms verification China, sms verification China, sms verification online free China, sms verification for free online China, two factor authentification free China, two factor authentification online China, verify my account for free China,
bypass sms verification with free number China, free virtual phone number China, Virtual Freephone Numbers China, Best FREE Virtual Phone Number For Your Business China, Free Virtual Number China, Free Virtual Phone Number China, get a free virtual number China, free virtual number Nike China, free virtual number Dream11 China, free virtual number Telegram China, free virtual number Whatsapp China, get a free phone number China, get free WhatsApp virtual number China, get free facebook virtual number China, get free amazon virtual number China, get free tiktok virtual number China, get free gmail virtual number China, get free Twitter virtual number China, free Instagram virtual number China, free LinkedIn virtual number China, free Snapchat virtual number China, free Pinterest virtual number China,
free PayPal virtual number China, free Coinbase virtual number China, free GitHub virtual number China, free virtual number VKontakte China, free virtual number WeChat China, free virtual number Telegram China, free virtual number OK China, free virtual number Viber China, free virtual number Uber China, free virtual number Gett China, free virtual number OLX China, free virtual number Mail.ru China, free virtual number Microsoft China, free virtual number Airbnb China, free virtual number Line messenger China, free virtual number Yahoo China, free virtual number DrugVokrug China, free virtual number X5ID China, free virtual number Delivery Club China, free virtual number Yandex China, free virtual number Steam China, free virtual number Tinder China, free virtual number Mamba China, free virtual number KakaoTalk China, free virtual number AOL China, free virtual number Tencent QQ China,
free number for sms verification Korea, sms verification Korea, sms verification online free Korea, sms verification for free online Korea, two factor authentification free Korea, two factor authentification online Korea, verify my account for free Korea, bypass sms verification with free number Korea, free virtual phone number Korea, Virtual Freephone Numbers Korea, Best FREE Virtual Phone Number For Your Business Korea, Free Virtual Number Korea, Free Virtual Phone Number Korea, get a free virtual number Korea, free virtual number Nike Korea, free virtual number Dream11 Korea, free virtual number Telegram Korea, free virtual number Whatsapp Korea, get a free phone number Korea, get free WhatsApp virtual number Korea, get free facebook virtual number Korea, get free amazon virtual number Korea, get free tiktok virtual number Korea, get free gmail virtual number Korea, get free Twitter virtual number Korea,
free Instagram virtual number Korea, free LinkedIn virtual number Korea, free Snapchat virtual number Korea, free Pinterest virtual number Korea, free PayPal virtual number Korea, free Coinbase virtual number Korea, free GitHub virtual number Korea, free virtual number VKontakte Korea, free virtual number WeChat Korea, free virtual number Telegram Korea, free virtual number OK Korea, free virtual number Viber Korea, free virtual number Uber Korea, free virtual number Gett Korea, free virtual number OLX Korea, free virtual number Mail.ru Korea, free virtual number Microsoft Korea, free virtual number Airbnb Korea, free virtual number Line messenger Korea, free virtual number Yahoo Korea, free virtual number DrugVokrug Korea, free virtual number X5ID Korea, free virtual number Delivery Club Korea, free virtual number Yandex Korea, free virtual number Steam Korea, free virtual number Tinder Korea, free virtual number Mamba Korea, free virtual number KakaoTalk Korea, free virtual number AOL Korea, free virtual number Tencent QQ Korea,
free number for sms verification Japan, sms verification Japan, sms verification online free Japan, sms verification for free online Japan, two factor authentification free Japan, two factor authentification online Japan, verify my account for free Japan, bypass sms verification with free number Japan, free Instagram virtual number Japan, free LinkedIn virtual number Japan, free virtual number AOL Japan, free virtual number Tencent QQ Japan, free number for sms verification Morocco, sms verification Morocco, sms verification online free Morocco, sms verification for free online Morocco, two factor authentification free Morocco, two factor authentification online Morocco, verify my account for free Morocco, bypass sms verification with free number Morocco, free virtual phone number Morocco, Virtual Freephone Numbers Morocco,
free virtual number Telegram Morocco, free virtual number OK Morocco, free virtual number Viber Morocco, free virtual number Uber Morocco, free virtual number Gett Morocco, free virtual number OLX Morocco, free virtual number Mail.ru Morocco, free virtual number Microsoft Morocco, free virtual number Airbnb Morocco, free virtual number Line messenger Morocco, free virtual number Yahoo Morocco, free virtual number DrugVokrug Morocco, free virtual number X5ID Morocco, free virtual number Delivery Club Morocco, sms verification online free France, sms verification for free online France, two factor authentification free France, two factor authentification online France, verify my account for free France, bypass sms verification with free number France, free virtual phone number France, Virtual Freephone Numbers France,
Best FREE Virtual Phone Number For Your Business France, Free Virtual Number France, free virtual number Mail.ru France, verificación de sms en línea gratis, verificación de sms gratis en línea, Autenticación de dos factores gratis, autenticación de dos factores en línea, verificar mi cuenta gratis, omitir la verificación de sms con número gratuito, número de teléfono virtual gratuito, Números de teléfono gratuitos virtuales, El mejor número de teléfono virtual GRATIS para su negocio, Número virtual gratuito, Número de teléfono virtual gratuito, obtener un número virtual gratis, numero virtual gratis nike, numero virtual gratis dream11, numero virtual gratis telegram, numero virtual gratis whatsapp, obtener un número de teléfono gratuito, obtener número virtual de WhatsApp gratis, obtener numero virtual de facebook gratis, obtener número virtual de amazon gratis, obtener numero virtual tiktok gratis,
obtener numero virtual de gmail gratis, obtener número virtual de Twitter gratis, numero virtual de instagram gratis, número virtual de LinkedIn gratis, numero virtual de snapchat gratis, numero virtual de pinterest gratis, numero virtual paypal gratis, número virtual gratuito yesca, numero virtual gratis mamba, numero virtual gratis kakaotalk, numero virtual gratis aol, número virtual gratuito Tencent QQ, sms doğrulama için ücretsiz numara, sms doğrulama, sms doğrulama çevrimiçi ücretsiz, ücretsiz çevrimiçi sms doğrulama, ücretsiz iki faktörlü kimlik doğrulama, çevrimiçi iki faktörlü kimlik doğrulama, hesabımı ücretsiz olarak doğrula, ücretsiz numara ile sms doğrulamasını atla, ücretsiz sanal telefon numarası, Sanal Ücretsiz Telefon Numaraları, İşletmeniz İçin En İyi ÜCRETSİZ Sanal Telefon Numarası, Ücretsiz Sanal Numara, Ücretsiz Sanal Telefon Numarası,
ücretsiz bir sanal numara alın, ücretsiz sanal numara Nike, bedava telefon numarası al, ücretsiz WhatsApp sanal numarası alın, bedava facebook sanal numara alma, ücretsiz amazon sanal numarası alma, bedava tiktok sanal numara alma, ücretsiz gmail sanal numarası alma, ücretsiz Twitter sanal numarası alın, ücretsiz instagram sanal numara, ücretsiz LinkedIn sanal numarası, ücretsiz Snapchat sanal numarası, ücretsiz Pinterest sanal numarası, vérifier mon compte gratuitement, contourner la vérification par SMS avec un numéro gratuit, numéro de téléphone virtuel gratuit, Numéros gratuits virtuels, Meilleur numéro de téléphone virtuel GRATUIT pour votre entreprise, Numéro virtuel gratuit, Numéro de téléphone virtuel gratuit,
obtenir un numéro virtuel gratuit, numéro virtuel gratuit Nike, numéro virtuel gratuit Dream11, Télégramme de numéro virtuel gratuit, numéro virtuel gratuit Whatsapp, obtenir un numéro de téléphone gratuit, obtenez un numéro virtuel WhatsApp gratuit, obtenir un numéro virtuel facebook gratuit, obtenir un numéro virtuel amazon gratuit, obtenir un numéro virtuel tiktok gratuit,
obtenir un numéro virtuel gmail gratuit, obtenir un numéro virtuel Twitter gratuit, numéro virtuel Instagram gratuit, numéro virtuel LinkedIn gratuit, numéro virtuel Snapchat gratuit, numéro virtuel Pinterest gratuit, numéro virtuel PayPal gratuit, numéro virtuel Coinbase gratuit, numéro virtuel GitHub gratuit, numéro virtuel gratuit VKontakte, numéro virtuel gratuit WeChat, Télégramme de numéro virtuel gratuit, numéro virtuel gratuit OK, numéro virtuel gratuit Viber, numéro virtuel gratuit Uber, numéro virtuel gratuit Gett, numéro virtuel gratuit OLX, numéro virtuel gratuit Mail.ru, numéro virtuel gratuit Microsoft, numéro virtuel gratuit Airbnb, messager de ligne de numéro virtuel gratuit, numéro virtuel gratuit Yahoo, numéro virtuel gratuit DrugVokrug, numéro virtuel gratuit X5ID,
Numéro virtuel gratuit Delivery Club, numéro virtuel gratuit Yandex, numéro virtuel gratuit Steam, numéro virtuel gratuit Tinder, numéro virtuel gratuit Mamba, numéro virtuel gratuit KakaoTalk, numéro virtuel gratuit AOL, numéro virtuel gratuit Tencent QQ, kostenlose Nummer zur SMS-Bestätigung, SMS-Bestätigung, SMS-Bestätigung online kostenlos, SMS-Bestätigung kostenlos online, Zwei-Faktor-Authentifizierung kostenlos, Zwei-Faktor-Authentifizierung online, Verifiziere mein Konto kostenlos, SMS-Bestätigung mit kostenloser Nummer umgehen, kostenlose virtuelle Telefonnummer, Virtuelle gebührenfreie Nummern, Beste KOSTENLOSE virtuelle Telefonnummer für Ihr Unternehmen, Kostenlose virtuelle Nummer, Kostenlose virtuelle Telefonnummer, Holen Sie sich eine kostenlose virtuelle Nummer,
kostenlose virtuelle Nummer Nike, kostenlose virtuelle Nummer Dream11, kostenloses virtuelles Nummerntelegramm, kostenlose virtuelle Nummer WhatsApp, erhalten Sie eine kostenlose Telefonnummer, erhalten Sie eine kostenlose virtuelle WhatsApp-Nummer, Holen Sie sich eine kostenlose virtuelle Facebook-Nummer, Holen Sie sich eine kostenlose virtuelle Amazon-Nummer, Holen Sie sich eine kostenlose virtuelle Tiktok-Nummer, Holen Sie sich eine kostenlose virtuelle Gmail-Nummer, erhalten Sie eine kostenlose virtuelle Twitter-Nummer,"
/>
      </Helmet>
    <div className='freenumBG'>
      <div className='container-lg'>
        <div className='row'>
          <div className='col-lg-11 mx-auto'>
            <h1 className='title_free'>{`${t('overview.free-virt-numbers')}`}</h1> 
            <p className='sub_title'>{`${t('overview.get-yours')}`}</p>
            <div className='box_please card'>
              <div className='card-body'>
                <h2 className='title_free_scnd'>{`${t('overview.pls-note')}`}</h2>
                <p className='text_free'> {`${t('overview.pls-notedes')}`}</p>
                <div className='free_numbers_main'>
                  <div className='row'>
                    <div className='col-md-5 col-lg-4'>
                      <ul className='numbers_list'>
                        {data.phones && data.phones.length > 0 ? 
                          data.phones.map((phone, index) => (

                              <li key={phone.number}
                                  onClick={() => updateIndex(index)}
                              >
                                {}
                                <span className='flag_icon'><img className='w-100 h-100' src={usaflag} alt="icon" /></span>
                                <span className={`${(index === currentNumberIndex) ? 'active' : ''} phone_number`} >{phone.number}</span>
                              </li>

                          ))
                        : (<p>{`${t('overview.no-phone')}`}</p>)}
                      </ul>
                    </div>
                    <div className='col-lg-8 col-md-7'>
                      <div className={`select_free_number ${(currentNumberIndex != null) ? '' : 'empty'}`}>
                        <div className='number_header'>
                          <div className='left'>
                            <span>{`${t('overview.messages')}`}</span>
                            <button onClick={ListFreeNumbers}><i className="bi bi-arrow-counterclockwise"></i></button>
                          </div>
        
                          <div className='right'>
                            <span className='phone_number pe-3'>{(currentNumber) ? currentNumber.number : ''}</span>
                            <span className='flag_icon'><img className='w-100 h-100' src={usaflag} alt="icon"/></span>
                          </div>
                        </div>
                        <div className='messages'>
                          <ul className='mb-0'>
                          {
                            (currentNumber.messages && currentNumber.messages.length > 0) ?
                            currentNumber.messages.map((message, index) => (
                              <li key={index} className="message_item">
                                <div className='icon'><img className='w-100 h-100' src={messageIcn2} alt="icon"/></div>
                                <div className='content w-100'>
                                  <div className='left w-100'>
                                    <div className='d-flex justify-content-between'>
                                      <div className='from'>{message.from}</div>
                                      <div className='date d-none d-sm-block d-md-none d-lg-block'>{new Date(message.date).toJSON().slice(0, 10)} {new Date(message.date).toLocaleTimeString()}</div>
                                    </div>
                                    <div className='body pe-4'>{message.body}</div>
                                  </div>
                                  <div className='right'>
                                    <div className='date d-sm-none d-md-block d-lg-none mt-2'>{new Date(message.date).toJSON().slice(0, 10)} {new Date(message.date).toLocaleTimeString()}</div>
                                  </div>
                                </div>
                              </li>
                            ))
                            : (currentNumberIndex != null) ? (<p>No data found</p>) 
                              : 
                                ([0,1]).map((value) => (
                                  <li className="message_item">
                                    <div className='icon'></div>
                                    <div className='content'>
                                      <div className='left'>
                                        <div className='from'></div>
                                        <div className='body'></div>
                                      </div>
                                      <div className='right'>
                                        <div className='date'></div>
                                        <div className='code'></div>
                                      </div>
                                    </div>
                                  </li>
                                ))
                          }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </>
  );
}
export default Freenum;