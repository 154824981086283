import React, { useState, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import { ImCheckmark } from "react-icons/im";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "swiper/css";
import "swiper/css/navigation";
import "./payment.css";

const visa_img = require("../../images/fiatImages/visa.png");
const alipay_img = require("../../images/fiatImages/alipay.png");
const capitalist_img = require("../../images/fiatImages/capitalist.png");
const perfect_money_img = require("../../images/fiatImages/perfect-money.png");

const ethereum_img = require("../../images/cryptoImages/ethereum.png");
const bitcoin_img = require("../../images/cryptoImages/bitcoin.png");
const monero_img = require("../../images/cryptoImages/monero.png");
const ripple_img = require("../../images/cryptoImages/ripple.png");
const bnb_img = require("../../images/cryptoImages/bnb.png");
const ltc_img = require("../../images/cryptoImages/LTC.png");
const doge_img = require("../../images/cryptoImages/doge.png");
const dash_img = require("../../images/cryptoImages/dash.png");
const trx_img = require("../../images/cryptoImages/trx.png");
const usdt_img = require("../../images/cryptoImages/USDTTRC20.png");
//stop trolling gitlab
const Payment = (props) => {
  const { amountSection, setIdDataGet } = props;

  const { t } = useTranslation();

  const fiatArray = [
    {
      id: 1,
      img: visa_img,
      value: "Bank Cards",
    },
    // {
    //   id: 5,
    //   img: alipay_img,
    //   value: "Alipay",
    // },
    // {
    //   id: 2,
    //   img: capitalist_img,
    //   value: "Capitalist",
    // },

    // {
    //   id: 4,
    //   img: perfect_money_img,
    //   value: "Perfect Money",
    // },
  ];

  const cryptoArray = [
    {
      id: 18,
      img: usdt_img,
      value: "USDTTRC20",
    },
    {
      id: 6,
      img: bitcoin_img,
      value: "BTC",
    },
   
   
    // {
    //   id: 7,
    //   img: dash_img,
    //   value: "Dash"
    // },
    // {
    //   id: 8,
    //   img: ethereum_img,
    //   value: "ETH",
    // },
    {
      id: 9,
      img: monero_img,
      value: "XMR",
    },
   ,
    {
      id: 14,
      img: bnb_img,
      value: "BNB",
    },
    {
      id: 15,
      img: ltc_img,
      value: "LTC",
    },
    {
      id: 13,
      img: doge_img,
      value: "DOGE",
    },
    {
      id: 12,
      img: dash_img,
      value: "DASH",
    },
    {
      id: 20,
      img: trx_img,
      value: "TRX",
    },
 
  ];

  const [isFiat, setFiat] = useState(false);
  const [isCrypto, setCrypto] = useState(true);
  const [fiatPayments, setFiatPayments] = useState(fiatArray);
  const [cryptoPayments, setCryptoMPayments] = useState(cryptoArray);
  const [fiatPaymentSelected, setFiatPaymentSelected] = useState(1);
  const [cryptoPaymentSelected, setCryptoPaymentSelected] = useState(5);
  // const [firstSelect, setFirstSelect] = useState(true);

  // setTimeout(function () {
  //   if (firstSelect) {
  //     var d = document.getElementById("BTC");
  //     d.className += " payment-option-selected";
  //     setFirstSelect(false);
  //   }
  // }, 500);

  function handleFiatPayment() {
    setFiat(true);
    setCrypto(false);
    setTimeout(() => {
      document.getElementsByClassName("slick-track")[0].style.transform =
        "translate3d(0px, 0px, 0px)";
    }, 10);
  }

  function handleCryptoPayment() {
    setCrypto(true);
    setFiat(false);
    setTimeout(() => {
      document.getElementsByClassName("slick-track")[0].style.transform =
        "translate3d(0px, 0px, 0px)";
    }, 10);
  }

  function handleFiatSelection(index) {
    setFiatPaymentSelected(index);
  }

  function handleCryptoSelection(index, id) {
    setCryptoPaymentSelected(index);    
    setIdDataGet(id);
    // setFirstSelect(false);
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const fiatPaymentList = fiatPayments.map((el, index) => {
  //   return (
  //     // <SwiperSlide key={el.id}>
  //     <div
  //       id={el.value}
  //       className={`payment-option card-payment h-100 ${
  //         fiatPaymentSelected === index ? "payment-option-selected" : ""
  //       }`}
  //       onClick={() => handleFiatSelection(index)}
  //     >
  //       <div className=" h-100 payelogoicon">
  //         <img alt={`${el.value}`} src={el.img} />
  //       </div>
  //     </div>
  //     // </SwiperSlide>
  //   );
  // });

  // console.log("cryptoPayments", cryptoPayments);
  const cryptoPaymentList = cryptoPayments.map((el, index) => {
    let classValue = "";
    if (cryptoPaymentSelected === index) {
      classValue = "payment-option card-payment h-100 payment-option-selected";
    } else {
      classValue = "payment-option card-payment h-100";
    }

    return (
      // <SwiperSlide key={el.id}>
      <div
        id={el.value}
        className={classValue}
        // className={`payment-option card-payment h-100 ${
        //   cryptoPaymentSelected === index ? "payment-option-selected" : ""
        // }`}
        onClick={() => handleCryptoSelection(index, el.id)}
      >
        <div className="h-100 payelogoicon">
          <img alt={`${el.value}`} src={el.img} />
        </div>
      </div>
      // </SwiperSlide>
    );
  });

  return (
    <div className="paymentBG">
      <div className="container table-card mb-4 change-password-min-height">
        <div className="row">
          <h1
            className="text-align-center title1"
            style={{ fontSize: "50px" }}
          >{`${t("overview.deposit")}`}</h1>
        </div>
        <p
          className="text-center instruction-text Choosepayment"
          style={{ fontSize: "23px" }}
        >
          {`${t("overview.payment-method")}`}
        </p>
        <div className="d-flex gap-3 justify-content-center payelogo">
          <div className="text-center py-3" style={{ marginBottom: "20px" }}>
            <button
              className={`${
                isCrypto ? "btn-payment-selected" : "btn-payment"
              } btn-crypto`}
              onClick={handleCryptoPayment}
            >
              <span className="px-4">{`${t("overview.crypto")}`}</span>
            </button>
          </div>
          <div className="text-center py-3" style={{ marginBottom: "20px" }}>
            <button
              className={`${
                isFiat ? "btn-payment-selected" : "btn-payment"
              } btn-fiat`}
              onClick={handleFiatPayment}
            >
              <span className="px-4">{`${t("overview.fiat")}`}</span>
            </button>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-lg-10 mx-auto position-relative px-5 px-md-0">
            {/* <Swiper
              modules={[Navigation]}
              spaceBetween={20}
              slidesPerView={2}
              navigation={{
                prevEl: ".prev-button",
                nextEl: ".next-button",
              }}
              breakpoints={{
                626: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                750: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {isFiat ? fiatPaymentList : cryptoPaymentList}
            </Swiper> 
            
            <button className="next-button">
              <span className="bi bi-chevron-left"></span>
            </button>
            <button className="prev-button">
              <span className="bi bi-chevron-right"></span>
            </button>
            */}
            {/* <Slider {...settings}>
              {isFiat ? fiatPaymentList : cryptoPaymentList}
            </Slider> */}
          {/* </div> */} 

        <div className="mt-4 mb-3">
          <p className="text-center instruction-text pb-0 payetitle">{`${t(
            "overview.specify-amount"
          )}`}</p>
        </div>

        {amountSection}
      </div>
    </div>
  );
};

export default Payment;
