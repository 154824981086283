import axios from "axios";
import { useParams } from "react-router-dom";

// const BASE_URL = 'https://simswitchapp.herokuapp.com';
const BASE_URL = "https://production2fapva.herokuapp.com";
const DEPOSIT_URL = process.env.REACT_APP_DEPOSIT_URL;

const auth = () => `Token ${localStorage.getItem("token")}`;

export const balanceHistoryCall = () => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${BASE_URL}/order/balance_history`,
  });
};
export const getRentListActive = () => {

  return axios({
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      Authorization: auth()
    },
    url: "https://production2fapva.herokuapp.com/order/get_rent_list_active",
  });
};

export const getRentList = () => {

  return axios({
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      Authorization: auth()
    },
    url: "https://production2fapva.herokuapp.com/order/get_rent_list",
  });
};


export const appPlaceRent = (time, country) => {
  
  return axios({
    
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: "https://production2fapva.herokuapp.com/order/app_place_rent",
    data: {
      country: country,
      time: time,
    },
  });
};

export const depositCallCrypto = (amount, method_payment) => {
  return axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${DEPOSIT_URL}`,
    data: { amount, method_payment },
  });
};
export const depositCallFiat = (amount, method_payment) => {
  return axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${DEPOSIT_URL}/fiat`,
    data: { amount, method_payment },
  });
};

export const orderListCall = () => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${BASE_URL}/order/list`,
  });
};

export const cancelCall = (id) => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${BASE_URL}/order/cancel/${id}`,
  });
};

export const finishCall = (id) => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${BASE_URL}/order/finish/${id}`,
  });
};

export const buyCall = (buyData) => {
  return axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${BASE_URL}/order/app_place`,
    data: buyData,
  });
};

export const changePassCall = (passData) => {
  return axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${BASE_URL}/user/change_password`,
    data: passData,
  });
};

export const keyCall = () => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${BASE_URL}/user/key`,
  });
};

export const activeOrdersCall = () => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${BASE_URL}/order/list_active`,
  });
};

export const loginCall = (loginData) => {
  return axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${BASE_URL}/user/signin`,
    data: {
      email: loginData.email,
      password: loginData.password,
    },
  });
};
export const loginCallWithGoogle = (res) => {
  return axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${BASE_URL}/rest-auth/google/`,
    data: {
      access_token: res.accessToken,
    },
  });
};
export const loginTokenWithGoogle = (res) => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${BASE_URL}/user/get-token?email=${res}`,
  });
};

export const verifyEmailCall = (userid, token) => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${BASE_URL}/user/verify-email/${token}/${userid}/`,
  });
};

export const signUpCall = (signupData, hcaptcha_response) => {
  return axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${BASE_URL}/user/signup`,
    data: {
      email: signupData.email,
      password: signupData.password,
      confirm_password: signupData.confirm_password,
      passcode: hcaptcha_response,
    },
  });
};

// export const forgerPassCall = (email) => {
//   return axios({
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     url: `${BASE_URL}/user/reset_password`,
//     data: {
//       email: email,
//     },
//   });
// };

// export const resetPassCall = (pass, token) => {
//   return axios({
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     url: `${BASE_URL}/user/reset_passwordconfirm/?token=${token}`,
//     data: {
//       password: pass.password,
//       token: token,
//     },
//   });
// };

export const getFreeNumbers = () => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: "https://app-name20.herokuapp.com/retrieve_free/numbers",
  });
};

export const forgerPassCall = (email) => {
  return axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${BASE_URL}/user/forget-password/`,
    data: {
      email: email,
    },
  });
};

export const resetPassCall = (pass, token, userid) => {
  return axios({
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${BASE_URL}/user/reset-password/${token}/${userid}/`,
    data: {
      password: pass.password,
      confirm_password: pass.confirmPass,
      token: token,
    },
  });
};

export const cancelRentOrder = (id) => {
  return axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth(),
    },
    url: `${BASE_URL}/order/cancel_place_rent/${id}`
  });
}