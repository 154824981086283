import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "./../ThanksPage/ThanksPage.css";
import { useTranslation } from "react-i18next";

export default function ThanksPage(props) {
  const {
    isLoggedIn,
    setIsLoggedIn,
    setShowModal,
    showModal,
    setBalance,
    loader,
    setLoader,
    setShowModal2,
    showModal2,
  } = props;
  //   const { successPage } = useParams();

  //   const [isLoading, setIsLoading] = useState(true);

  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  //   setTimeout(function () {
  //     setIsLoading(false);
  //   }, 1200);
  const { t } = useTranslation();

  return (
    <>
      {loader ? (
        <div className="thankyoupage table-min-height">
          <div className="login-content-registering">
            <div className="thanktext">
              <div className="logo-content">
                <a href="/">
                  <img
                    src="/img/2falogo.svg"
                    alt="airbnb"
                    width="200px"
                    height="100px"
                    rel="preload"
                  />
                  {/* <img src="/img/2falogo.svg" className="login-logo" alt="" /> */}
                </a>
              </div>

              <p>
              {`${t('overview.thank-you')}`}
                {/* {!successPage && (
                  <> */}
                <br /> {`${t('overview.em-sent')}`}
                <br /> {`${t('overview.verify-em')}`}
                {/* </>
                )} */}
                {/* {successPage && (
                  <> */}
                <br /> {`${t('overview.log-in-to')}`}
                {/* </>
                )} */}
              </p>
              <div className="center mt-2 thankyoudiv">
                <NavLink
                  onClick={(e) => {
                    setShowModal(true);
                  }}
                  to="/"
                  className="btn btn-primary btnColor thankbutton"
                >
                  {`${t('overview.log-inn')}`}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner animation="border" role="status" className="loader"></Spinner>
      )}
    </>
  );
}
