import React from "react";
import { useTranslation } from "react-i18next";

import "./Footer-pages.css";
import { Termsusedata } from "../../Container/2FA-Alldata";
import { Helmet } from "react-helmet";

const Terms_use = () => {

  const { t } = useTranslation();

  return (
    <div className="bg-white">
       <Helmet>
       <meta name="title" content="Terms of Use | 2FAPVA" />
        <meta name="description" content="Understand the terms and conditions for using our website and services on our Terms of Use page. Review our policies on usage, intellectual property, and disclaimer of liability." />
        <meta name="keywords" content="virtual phone number agreement, virtual phone number terms of service, virtual phone number contract, virtual phone number privacy policy, virtual phone number terms and conditions, terms of use for virtual phone numbers, legal agreement for virtual phone numbers, virtual phone number service agreement, virtual phone number usage agreement, virtual phone number usage policy, virtual phone number provider agreement, virtual phone" />
      </Helmet>
      <div className="container-lg  table-card same-space-bottom-">
        <h1 className="text-center title2">{`${t('terms-use-data.main_title')}`}</h1>
        <div className="privacypage">
          <p className="retuen1 termsdesone">{`${t('terms-use-data.desone')}`}</p>
          <p className="conditions">{`${t('terms-use-data.destwo')}`}</p>
          <p className="retuen1">{`${t('terms-use-data.desthree')}`}</p>
          <p className="retuen1">{`${t('terms-use-data.desfour')}`}</p>
          <p className="retuen1">{`${t('terms-use-data.desfive')}`}</p>
          <h2 className="tilte-privacy">{`${t('terms-use-data.title')}`}</h2>
          <p className="retuen1">{`${t('terms-use-data.subjectdes1')}`}</p>
          <p className="retuen1">{`${t('terms-use-data.subjectdes2')}`}</p>
          <p className="retuen1">{`${t('terms-use-data.subjectdes3')}`}</p>
          <p className="retuen1">{`${t('terms-use-data.subjectdes4')}`}</p>
          <p className="retuen1">{`${t('terms-use-data.subjectdes5')}`}</p>
          <p className="termsdes">{`${t('terms-use-data.subjectdes6')}`}</p>
          <h2 className="tilte-privacy">{`${t('terms-use-data.title1')}`}</h2>
          <p className="termsdes1">{`${t('terms-use-data.servicedes')}`}</p>
          <h2 className="tilte-privacy">{`${t('terms-use-data.title2')}`}</h2>
          <p className="termsdes1">{`${t('terms-use-data.privacydes')}`}</p>
          <h2 className="tilte-privacy">{`${t('terms-use-data.title3')}`}</h2>
          <p className="termsdes1">{`${t('terms-use-data.limitationdes')}`}</p>
          <h2 className="tilte-privacy">{`${t('terms-use-data.title4')}`}</h2>
          <p className="retuen1">{`${t('terms-use-data.generaldes1')}`}</p>
          <p className="retuen1">{`${t('terms-use-data.generaldes2')}`}</p>
          <p className="retuen1">{`${t('terms-use-data.generaldes3')}`}</p>
          <p className="termsdes1">{`${t('terms-use-data.generaldes4')}`}</p>
          <h2 className="tilte-privacy">{`${t('terms-use-data.title5')}`}</h2>
          <p className="termsdes1">{`${t('terms-use-data.changesdes')}`}</p>
        </div>
        {/* <div className="privacy-policy-main footerpages-height">
        <div className="footerall-content">
          <h4>Comming Soon</h4>
        </div>
      </div> */}
        </div>
      </div>
  
  );
};

export default Terms_use;




