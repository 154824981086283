import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import Spinner from "react-bootstrap/Spinner";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { useTranslation } from "react-i18next";

import "./../login/login.css";
import {
  signUpCall,
  loginCallWithGoogle,
  loginTokenWithGoogle,
} from "../../calls";
import "./signup.css";
import { gapi } from "gapi-script";

Modal.setAppElement("#root");

const axios = require("axios");

const sitekey = "4c3e00a9-8947-458a-8e06-46df45f1ae1e";

const Signup = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isLoggedIn,
    setIsLoggedIn,
    setShowModal2,
    setShowModal,
    showModal2,
    loader,
    setLoader,
  } = props;

  const [signUpError, setSignUpError] = useState([]);

  const [signupData, setSignupData] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });

  const [token_signup, setTokenSignUp] = useState(null);

  const [trySignUp, setTrySignUp] = useState(false);

  const [isPassHidden, setPassHidden] = useState(true);
  const [isConfirmPassHidden, setConfirmPassHidden] = useState(true);

  const captchaRefSignUp = useRef(null);

  useEffect(() => {
    if (showModal2) {
      setTimeout(() => {
        changeVisibilityPass();
        changeVisibilityConfirmPass();
      }, 100);
    }

    //change the visibility of the password field
    function changeVisibilityPass() {
      if (!isPassHidden) {
        document.querySelector("#password").type = "text";
      } else {
        document.querySelector("#password").type = "password";
      }
    }

    //change the visibility of the confirm password field
    function changeVisibilityConfirmPass() {
      if (!isConfirmPassHidden) {
        document.querySelector("#confirm_password").type = "text";
      } else {
        document.querySelector("#confirm_password").type = "password";
      }
    }
  }, [isPassHidden, isConfirmPassHidden, showModal2]);

  const handlePassHidden = () => {
    setPassHidden(!isPassHidden);
  };

  const handleConfirmPassHidden = () => {
    setConfirmPassHidden(!isConfirmPassHidden);
  };

  const handleDataChange = (e) => {
    setSignupData({
      ...signupData,
      [e.target.name]: e.target.value,
    });
  };

  const handleVerificationSuccess = (token, eKey) => {
    setTokenSignUp(token);
    // console.log(`Here is the token: \n\t ${token}`);
    // console.log(`Here is the ekey: \n\t ${eKey}`);
  };

  const handleSubmit = (e) => {
    setLoader(false);
    e.preventDefault();
    setTrySignUp(true);
    if (!token_signup) {
      console.log("error can't sign up");
    } else {
      // signUpCall(signupData, token_signup)
      //   .then((response) => {
      //     navigate(`en/registration`);
      //     setLoader(true);
      //     const { data } = response;
      //     // localStorage.setItem("token", data.token);
      //     // setIsLoggedIn(true);
      //     setShowModal2(false);
      //     resetAfterSignUp();
      //   })
      //   .catch((err) => {
      //     setLoader(true);
      //     console.log(err.response.data);
      //     if (err.response && err.response.status === 400) {
      //       if (err.response.data.hasOwnProperty("email")) {
      //         setSignUpError([err.response.data.email[0]]);
      //       } else if (err.response.data.hasOwnProperty("password")) {
      //         setSignUpError([err.response.data.password[0]]);
      //       } else if (err.response.data.hasOwnProperty("confirm_password")) {
      //         setSignUpError([err.response.data.confirm_password[0]]);
      //       }
      //     }
      //     resetAfterSignUpError();
      //   });
      signUpCall(signupData, token_signup)
        .then((response) => {
          console.log("successful sign up age");
          setLoader(true);
          const { data } = response;
          console.log("Response: ", response);
          localStorage.setItem("token", data.token);
          setIsLoggedIn(true);
          console.log("Signup -> SignIn");
          setShowModal2(false);
          console.log(showModal2);
          resetAfterSignUp();
        })
        .catch((err) => {
          setLoader(true);
          console.log("y ange");
          console.log(err.response.data);
          if (err.response && err.response.status === 400) {
            if (err.response.data.hasOwnProperty("email")) {
              setSignUpError([err.response.data.email[0]]);
            } else if (err.response.data.hasOwnProperty("password")) {
              setSignUpError([err.response.data.password[0]]);
            } else if (err.response.data.hasOwnProperty("confirm_password")) {
              setSignUpError([err.response.data.confirm_password[0]]);
            }
          }
          resetAfterSignUpError();
        });
    }
  };

  // reset state after successful sign up
  const resetAfterSignUp = () => {
    setConfirmPassHidden(true);
    setPassHidden(true);
    setTrySignUp(false);
    setSignupData({ email: "", password: "", confirm_password: "" });
    setTokenSignUp(null);
    captchaRefSignUp.current.resetCaptcha();
  };

  // reset state after sign up error
  const resetAfterSignUpError = () => {
    setTrySignUp(false);
    setTokenSignUp(null);
    captchaRefSignUp.current.resetCaptcha();
  };

  const errorMsg = signUpError.map((item, index) => {
    return (
      <h5 key={index} className="error_msg">
        {item}
      </h5>
    );
  });

  const customStyles = {
    content: {
      boxSizing: "border-box",
      position: "fixed",
      width: "465px",
      height: "550px",
      maxWidth: "calc(100% - 24px)",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      background: "#FFFFFF",
      border: "1px solid #F4F4F4",
      borderRadius: "50px",
    },
  };

  /************* Google Login *************/
  const clientId =
    "408276211340-7s7qu57gc268o4ok89oicm04648m092s.apps.googleusercontent.com";

  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: "",
  //     });
  //   };
  //   gapi.load("client:auth2", initClient);
  // });

  // const onLoginSuccces = (res) => {
  //   setTrySignUp(true);
  //   if (!res) {
  //     console.log("error can't sign up");
  //   } else {
  //     loginCallWithGoogle(res)
  //       .then((response) => {
  //         console.log(response);
  //         loginTokenWithGoogle(res?.wt?.cu)
  //           .then((response) => {
  //             console.log("google tocken", response);
  //             const { data } = response;
  //             console.log(response);
  //             localStorage.setItem("token", data.token);
  //             setIsLoggedIn(true);
  //             setShowModal2(false);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       })
  //       .catch((err) => {
  //         setLoader(true);
  //         if (err.response && err.response.status === 400) {
  //           setSignUpError(["Invalid Email"]);
  //         }
  //       });
  //   }
  // };

  const onloginFailure = (res) => {
    console.log("login failure", res);
  };
  /************* Google Login *************/

  return (
    <div className="row ">
      {loader ? (
        <Modal
          isOpen={showModal2}
          onRequestClose={() => setShowModal2(false)}
          style={customStyles}
          contentLabel="Sign Up"
        >
          <div className="title d-flex justify-content-between signuppage">
            <span></span>
            <h1 className="modal-title text-center auth-modal-header-text sign-up-title">
              {`${t("overview.sign-up")}`}
            </h1>
            <button
              type="button"
              onClick={() => setShowModal2(false)}
              className="btn-close closButton"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {/* <img src="/img/btn1.png" /> */}
            </button>
          </div>
          <form className="row loginsec" onSubmit={handleSubmit}>
            <div className="input-group ">
              {/* <label for="email" className="form-label">Email address</label> */}
              <span className="signupimg">
                <img
                  src="/img/login.svg"
                  alt="airbnb"
                  width="25px"
                  height="25px"
                  rel="preload"
                ></img>
              </span>

              <input
                required
                value={signupData.email}
                onChange={handleDataChange}
                type="email"
                className="form-control "
                id="email"
                name="email"
                placeholder="Email"
              />
            </div>
            <div className="input-group">
              {/* <label for="password" className="form-label">Password</label> */}
              <span className="signupimg">
                <img
                  src="/img/pass.svg"
                  alt="airbnb"
                  width="25px"
                  height="25px"
                  rel="preload"
                ></img>
              </span>

              <input
                required
                value={signupData.password}
                onChange={handleDataChange}
                type="password"
                className="form-control inputFieldsig"
                id="password"
                name="password"
                placeholder="Password"
              />
              {isPassHidden ? (
                <i class="bi bi-eye-fill eye1" onClick={handlePassHidden}></i>
              ) : (
                <i
                  class="bi bi-eye-slash-fill eye1"
                  onClick={handlePassHidden}
                ></i>
              )}
            </div>
            <div className="input-group">
              {/* <label for="confirm_password" className="form-label">Confirm Password</label> */}
              <span className="signupimg"></span>
              <input
                required
                value={signupData.confirm_password}
                onChange={handleDataChange}
                type="password"
                className="form-control"
                id="confirm_password"
                name="confirm_password"
                placeholder="Confirm Password"
              />
              {isConfirmPassHidden ? (
                <i
                  class="bi bi-eye-fill eye2"
                  onClick={handleConfirmPassHidden}
                ></i>
              ) : (
                <i
                  class="bi bi-eye-slash-fill eye2"
                  onClick={handleConfirmPassHidden}
                ></i>
              )}
            </div>
            {errorMsg && <div className="mb-3 errorm">{errorMsg}</div>}
            {/* <NavLink
              onClick={() => {
                setShowModal(false);
              }}
              to="/forget-password"
              className={"linkSignUp Passwordp"}
            >
              {`${t("overview.forget-pass")}`}
            </NavLink> */}
            <div class="hcaptcha sigupcaptcha">
              <div style={{ marginTop: "0px" }}>
                <div className="captcha-container">
                  <HCaptcha
                    id="hcaptcha_signup"
                    sitekey={sitekey}
                    className="hcaptcha"
                    onVerify={(token, eKey) =>
                      handleVerificationSuccess(token, eKey)
                    }
                    ref={captchaRefSignUp}
                  />
                </div>
              </div>

              {!token_signup & trySignUp ? (
                <small className="hcaptcha_login_error">
                  {`${t("overview.captcha-required")}`}
                </small>
              ) : null}
            </div>
            {/* <div className="Already">Already registered? Log In</div> */}

            <div className="text-align-center margin-top-10 Logbtn">
              <button
                type="submit"
                className="text-align-center btn btn-primary btnColor2 w-70 custom-button-style"
              >
                {`${t("overview.sign-up")}`}
              </button>
            </div>
            <div className="margin-top-10 text-align-center Already">
              <text className="already-registered-text">
                {`${t("overview.already-registered")}`}
              </text>{" "}
              <NavLink
                onClick={() => {
                  setShowModal(true);
                  setShowModal2(false);
                }}
                to=""
                className={"linkSignUp"}
              >
                {`${t("overview.log-in")}`}
              </NavLink>
              <div className="row">{errorMsg}</div>
            </div>
          
            <div className="logintelegrm">
              

              <div className="Telegramdiv">
                <a
                  href="https://linktr.ee/2fapva"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa fa-telegram"></i>
                </a>
              </div>
            </div>
          </form>
        </Modal>
      ) : (
        <Spinner animation="border" role="status" className="loader"></Spinner>
      )}
    </div>
  );
};

export default Signup;
