import React, { useEffect, useState, useRef, useContext } from "react";
import "./404.css";
import { useTranslation } from "react-i18next";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const NoteFounfPage = (props) => {
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();

  return (
    <>
      <div className="container-lg forgetpasswords table-min-heightforget">
        <div className="forgetpasswordsform">
          <div className="">
            <div className="row margin-top-10 text-align-start font-size-small padding-top-10">
              <h1 className="title2Forget mb-6 text-center">{`${t('overview.error404')}`}</h1>
            </div>
            <p className="text-center">{`${t('overview.pagenotfound')}`}</p>

            <div className="text-align-end">
              <Link to="/" className="btn Forgetbtn">
              {`${t('overview.backhome')}`}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoteFounfPage;
