import "./help.css";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { faq_data } from "../../Container/2FA-Alldata";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t('faq.title')}`}</title>
        <meta
          name="description"
          content="Free Virtual Phone Number - Frequently asked questions Find answers to common questions about our SMS receiving service with virtual phone numbers. Learn how to use temporary numbers for verification, troubleshoot delivery issues, and contact our support team for assistance."
        />
         <meta
          name="title"
          content="Free Virtual Phone Number - Frequently asked questions"
        />
        <meta
          name="keywords"
          content="How to use virtual phone numbers for SMS verification,How to use virtual phone numbers for SMS verification,How to use virtual phone numbers for SMS verification,Virtual phone number SMS receiving service,Virtual phone number SMS receiving service,Virtual phone number SMS receiving service,Virtual phone numbers for SMS verification,Virtual phone numbers for SMS verification,Virtual phone numbers for SMS verification,Temporary virtual phone numbers for SMS verification,Temporary virtual phone numbers for SMS verification,Temporary virtual phone numbers for SMS verification,SMS receiving service with virtual phone numbers,SMS receiving service with virtual phone numbers,SMS receiving service with virtual phone numbers,How to troubleshoot SMS delivery issues with virtual phone numbers,How to troubleshoot SMS delivery issues with virtual phone numbers,How to troubleshoot SMS delivery issues with virtual phone numbers,Receiving SMS messages with virtual phone numbers,Receiving SMS messages with virtual phone numbers,Receiving SMS messages with virtual phone numbers,SMS Verification service FAQ,2fapva FAQ,2fapva Frequently asked questions,
          How to bypass SMS verification using virtual phone numbers?, What is the best virtual phone number provider for SMS verification?, How to get virtual phone numbers for SMS verification without disclosing personal information?, How to prevent virtual phone numbers from being blocked by SMS verification systems?, How to use virtual phone numbers for SMS verification on social media platforms?, How to verify multiple accounts using one virtual phone number?, How long do virtual phone numbers remain active for SMS verification?, Can virtual phone numbers be used for SMS verification of bank accounts?, What is the cost of using virtual phone numbers for SMS verification?, How to choose the right country code for virtual phone numbers when verifying accounts?,"
        />
        <meta name="author" content="2FAPVA" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" data-react-helmet="true" />
      </Helmet>
      <div className="bg-white">
        <div className="container-lg table-card table-min-height help">
          <h1 className="title1 text-center">{`${t('faq.title')}`}</h1>
          <p className="title3 text-center">{`${t('faq.sub_title')}`}</p>
          <div className="namebox">
            <Accordion defaultActiveKey="0" className="faq-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="question">
                  {`${t('faq.questions.question1')}`}
                </Accordion.Header>
                <Accordion.Body className="answer">
                  {`${t('faq.questions.answer1')}`} <br />
                  <br />
                  <ol>
                    <li>{`${t('faq.questions.step1')}`} </li>
                    <li>{`${t('faq.questions.step2')}`} </li>
                    <li>{`${t('faq.questions.step3')}`} </li>
                    <li>{`${t('faq.questions.step4')}`} </li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="question">
                  {`${t('faq.questions.question2')}`} 
                </Accordion.Header>
                <Accordion.Body className="answer">
                  {`${t('faq.questions.answer2')}`} 
                  <br />
                  <br />{" "}
                  <ol>
                    <li class="list-item">{`${t('faq.questions.step11')}`} </li>
                    <li class="list-item">{`${t('faq.questions.step22')}`} </li>
                    <li class="list-item">{`${t('faq.questions.step33')}`} </li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="question">
                  {`${t('faq.questions.question3')}`} 
                </Accordion.Header>
                <Accordion.Body className="answer">
                  {`${t('faq.questions.answer3')}`} 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header className="question">
                  {`${t('faq.questions.question4')}`} 
                </Accordion.Header>
                <Accordion.Body className="answer">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('faq.questions.answer4') ,
                    }}
                  ></p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header className="question">
                  {`${t('faq.questions.question5')}`} 
                </Accordion.Header>
                <Accordion.Body className="answer">
                  {`${t('faq.questions.answer5')}`} 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header className="question">
                  {`${t('faq.questions.question6')}`} 
                </Accordion.Header>
                <Accordion.Body className="answer">
                  {`${t('faq.questions.answer6')}`} 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header className="question">
                  {`${t('faq.questions.question7')}`} 
                </Accordion.Header>
                <Accordion.Body className="answer">
                  {`${t('faq.questions.answer7')}`} 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header className="question">
                  {`${t('faq.questions.question8')}`} 
                </Accordion.Header>
                <Accordion.Body className="answer">
                  {`${t('faq.questions.answer8')}`} 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header className="question">
                  {`${t('faq.questions.question9')}`} 
                </Accordion.Header>
                <Accordion.Body className="answer">
                  {`${t('faq.questions.answer9')}`} 
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQ;
