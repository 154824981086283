import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import ArticleJSON from './articlesData.json';

const SingleArticle = () => {
  const { slug } = useParams();
  const article = ArticleJSON.find((article) => article.slug === slug);

  if (!article) {
    return <div>Article not found</div>;
  }

  return (
    <div className="article-container">
      <Helmet>
        <title>{article.title}</title>
        <meta name="title" content={article.title} />
        <meta name="description" content={article.description} />
        {/* ... (autres métadonnées) */}
      </Helmet>

      <div className="article-section">
        {/* Contenu de l'article */}
        <div dangerouslySetInnerHTML={{ __html: article.content }} />
      </div>
    </div>
  );
};

export default SingleArticle;
