import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { balanceHistoryCall, changePassCall, keyCall } from "../../calls";
import ClipLoader from "react-spinners/ClipLoader";
import Error from "../../components/Error/Error";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

import logo from "./../../images/logoImg.svg";
import "./changePassword.css";

const ChangePass = (props) => {
  const { setIsLoggedIn, isLoggedIn, loader, setLoader } = props;

  const { t } = useTranslation();

  const data = [
    {
      old_password: "",
      password: "",
      confirm_password: "",
    },
  ];

  const [passData, setPassData] = useState(data);

  const [error, setError] = useState([]);

  const [errorS, setErrorS] = useState(false);

  const [success, setSuccess] = useState("");

  const [key, setKey] = useState("");

  const [oldPasShow, setOldPasShow] = useState(false);
  const [newPasShow, setNewPasShow] = useState(false);
  const [confirmPasShow, setConfirmPasShow] = useState(false);

  useEffect(() => {
    oldPasswordVisibility();
    newPasswordVisibility();
    confirmPasswordVisibility();

    // Change the visibility of the confirm password input field
    function confirmPasswordVisibility() {
      if (confirmPasShow) {
        document.querySelector("#confirm_password").type = "text";
      } else {
        document.querySelector("#confirm_password").type = "password";
      }
    }

    // Change the visibility of the old password input field
    function oldPasswordVisibility() {
      if (oldPasShow) {
        document.querySelector("#old_password").type = "text";
      } else {
        document.querySelector("#old_password").type = "password";
      }
    }

    // Change the visibility of the new password input field
    function newPasswordVisibility() {
      if (newPasShow) {
        document.querySelector("#password").type = "text";
      } else {
        document.querySelector("#password").type = "password";
      }
    }
  }, [confirmPasShow, newPasShow, oldPasShow]);

  const handleConfirmPasShow = (e) => {
    setConfirmPasShow(!confirmPasShow);
  };

  const handleOldPasShow = (e) => {
    setOldPasShow(!oldPasShow);
  };

  const handleNewPasShow = (e) => {
    setNewPasShow(!newPasShow);
  };

  const errorMsg = error.map((item, index) => {
    return (
      <h5 key={index} className="error_msg">
        {item}
      </h5>
    );
  });

  const handleDataChange = (e) => {
    setPassData({
      ...passData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    setLoader(false);
    setError([]);
    e.preventDefault();
    changePassCall(passData)
      .then((response) => {
        setLoader(true);
        const { data } = response;
        setPassData(data);
        setSuccess("Password Updated");
      })
      .catch((err) => {
        setLoader(true);

        console.log("ERROR: ", err.response.data);
        if (err.response && err.response.status === 401) {
          setIsLoggedIn(false);
          localStorage.removeItem("token");
        }
        if (
          err.response &&
          (err.response.status === 400 || err.response.status === 406)
        ) {
          if (err.response.data.hasOwnProperty("message")) {
            setError([err.response.data.message]);
            setErrorS(true);
          } else if (err.response.data.hasOwnProperty("confirm_password")) {
            setError([err.response.data.confirm_password]);
            setErrorS(true);
          }
        }
      });
  };

  const handleGetKey = () => {
    setLoader(false);
    keyCall()
      .then((response) => {
        setLoader(true);
        const { data } = response;
        setKey(data.key);
      })
      .catch((err) => {
        setLoader(true);
        console.log("ERROR: ", err.response.data);
        if (err.response && err.response.status === 401) {
          setIsLoggedIn(false);
          localStorage.removeItem("token");
        }
      });
  };

  if (!isLoggedIn) {
    console.log("navigating");
    return <Navigate replace to="/" />;
  } else
    return (
      <div className="container-lg">
        <div className="show row pt-5 mt-4 change-password-min-height">
          {!loader ? (
            <Spinner
              animation="border"
              role="status"
              className="loader"
            ></Spinner>
          ) : (
            <></>
          )}
          <div className="col-12 col-lg-6 mb-4 md-lg-0">
            <div className="listCard">
              <div className="">
                <div className="p-3">
                  <div className="margin-top-10 text-align-start font-size-small padding-top-10">
                    <h1 className="text-align-center social-cont1 changePass ps-0 ms-0 title1">
                      {`${t('overview.change-pass')}`}
                    </h1>
                  </div>
                  <div className="margin-top-10 data-row buy-btn text-align-start margin-bottom-10">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3 input-group">
                        {/* <label for="old_password" className="form-label">Old Password</label> */}
                        <input
                          required
                          value={passData.old_password}
                          onChange={handleDataChange}
                          type="password"
                          className="form-control maring-bottom-45 backG"
                          id="old_password"
                          name="old_password"
                          placeholder={`${t('overview.old-pass')}`}
                        />
                        {oldPasShow ? (
                          <i
                            class="bi bi-eye-slash-fill eye3"
                            onClick={(e) => handleOldPasShow(e)}
                          ></i>
                        ) : (
                          <i
                            class="bi bi-eye-fill eye3"
                            onClick={(e) => handleOldPasShow(e)}
                          ></i>
                        )}
                      </div>
                      <div className="mb-3 input-group">
                        {/* <label for="password" className="form-label">Password</label> */}
                        <input
                          required
                          value={passData.password}
                          onChange={handleDataChange}
                          type="password"
                          className="form-control maring-bottom-45 backG"
                          id="password"
                          name="password"
                          placeholder={`${t('overview.new-pass')}`}
                        />
                        {newPasShow ? (
                          <i
                            class="bi bi-eye-slash-fill eye4"
                            onClick={(e) => handleNewPasShow(e)}
                          ></i>
                        ) : (
                          <i
                            class="bi bi-eye-fill eye4"
                            onClick={(e) => handleNewPasShow(e)}
                          ></i>
                        )}
                      </div>
                      <div className="mb-3 input-group">
                        {/* <label for="confirm_password" className="form-label">Confirm Password</label>/ */}
                        <input
                          required
                          value={passData.confirm_password}
                          onChange={handleDataChange}
                          type="password"
                          className={
                            errorS
                              ? `form-control backG`
                              : `form-control backG maring-bottom-45`
                          }
                          id="confirm_password"
                          name="confirm_password"
                          placeholder={`${t('overview.confirm-new-pass')}`}
                        />
                        {confirmPasShow ? (
                          <i
                            class="bi bi-eye-slash-fill eye5"
                            onClick={(e) => handleConfirmPasShow(e)}
                          ></i>
                        ) : (
                          <i
                            class="bi bi-eye-fill eye5"
                            onClick={(e) => handleConfirmPasShow(e)}
                          ></i>
                        )}
                      </div>
                      <div className="row">{errorMsg}</div>
                      <div className="row">
                        <h5 className="success_msg">{success}</h5>
                      </div>
                      <div className="text-align-end">
                        <button
                          type="submit"
                          className="btn btn-primary btnColorChange greenPWD"
                        >
                          {`${t('overview.change-pass')}`}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="listCard2">
              <div className="row">
                <div className="col-3"></div>
                <img alt="logoimg" src={logo} className="logoImage"></img>
              </div>
              <div>
                <div className="margin-top-10 data-row px-3 pb-5 mx-0 locTXT">
                  <h6>
                    {`${t('overview.change-pass-des')}`}
                  </h6>
                </div>

                <div className="text-align-end px-3 margin-bottom-10">
                  {key === "" ? (
                    <button
                      type="submit"
                      className="btn btn-primary btnColorChange2 getKEY"
                      onClick={handleGetKey}
                    >
                      {`${t('overview.get-key')}`}
                    </button>
                  ) : (
                    <div className="btnColorChange3 getKEY">{key}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ChangePass;
