import React, { useState, useEffect } from "react";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import { verifyEmailCall } from "../../calls";
import Spinner from "react-bootstrap/Spinner";
import "./../VerifyEmail/EmailVerify.css";
import { useTranslation } from "react-i18next";

export default function EmailVerify(props) {
  const {
    isLoggedIn,
    setIsLoggedIn,
    setShowModal,
    showModal,
    setBalance,
    loader,
    setLoader,
    setShowModal2,
    showModal2,
  } = props;
  const { token, uid } = useParams();

  const [successChk, setSuccessChk] = useState(false);

  useEffect(() => {
    // setLoader(false);
    verifyEmailCall(uid, token)
      .then((response) => {
        console.log(response);
        setSuccessChk(true);
        setLoader(true);
      })
      .catch((err) => {
        setSuccessChk(false);
        console.log("HIstory Error: ", err);
        setLoader(true);
      });
  }, []);
  
  const { t } = useTranslation();
  return (
    <>
      {loader ? (
        <>
          <div className="thankyoupage table-min-height">
            <div className="login-content-registering">
              <div className="thanktext">
                <div className="logo-content">
                  <a href="/">
                    <img
                      src="/img/2falogo.svg"
                      alt="airbnb"
                      width="200px"
                      height="100px"
                      rel="preload"
                    />
                    {/* <img src="/img/2falogo.svg" className="login-logo" alt="" /> */}
                  </a>
                </div>
                {/* <h1>Welcome to 2FAPVA</h1> */}
                {successChk && (
                  <>
                    <p>
                    {`${t('overview.thankyou')}`}
                    </p>
                  </>
                )}
                {!successChk && (
                  <>
                    <p>{`${t('overview.oops')}`}</p>
                  </>
                )}
                <NavLink
                  onClick={(e) => {
                    setShowModal(true);
                  }}
                  to="/"
                  className="btn btn-primary btnColor thankbutton"
                >
                  {`${t('overview.loginn')}`}
                </NavLink>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner animation="border" role="status" className="loader"></Spinner>
      )}
    </>
  );
}
