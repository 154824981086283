import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { balanceHistoryCall } from "../../calls";
import Error from "../../components/Error/Error";
import Spinner from "react-bootstrap/Spinner";
import "../../components/login/login.css";
import "./balanceHistory.css";
import { useTranslation } from "react-i18next";

const BalanceHistory = (props) => {
  const {
    setIsLoggedIn,
    isLoggedIn,
    showErrorModal,
    setShowErrorModal,
    errorMsg,
    setErrorMsg,
    loader,
    setLoader,
  } = props;

  const showError = () => {
    return (
      <div className="row">
        <Error
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          errorMsg={errorMsg}
        ></Error>
      </div>
    );
  };

  const data = [
    {
      created_at: "",
      amount: 0.0,
    },
  ];

  const [historyData, setHistoryData] = useState(data);
  const { t } = useTranslation();

  const historyList = historyData.map((item, index) => {
    const date = new Date(item.created_at);
    console.log("Amount: ", localStorage.getItem("token"));
    return (
      <tr key={index} className="listL">
        <th scope="row">{index + 1}</th>
        <td>{item.amount} $</td>
        <td>{date.toDateString()}</td>
        <td className="lastEl">{date.toTimeString()}</td>
      </tr>
    );
  });

  useEffect(() => {
    setLoader(false);
    balanceHistoryCall()
      .then((response) => {
        setLoader(true);
        setHistoryData(response.data);
      })
      .catch((err) => {
        setLoader(true);
        console.log("HIstory Error: ", err);
        if (err.response && err.response.status === 401) {
          setIsLoggedIn(false);
          localStorage.removeItem("token");
        }
        setShowErrorModal(true);
        setErrorMsg([err.message]);
      });
  }, [setIsLoggedIn, setErrorMsg, setShowErrorModal]);

  if (!isLoggedIn) {
    console.log("navigating");
    return <Navigate replace to="/" />;
  } else
    return (
      <div className="container-lg">
        {showError()}
        <div className="row ">
          {loader ? (
            <div className="col-12 table-card">
              <div className="row">
                <h1 className="text-align-center bal">{`${t('overview.balance-history')}`}</h1>
                <h6 className="text-align-center margin-bottom-10 entries">
                  {historyData.length} {`${t('overview.entries-total')}`}
                </h6>
              </div>
              <div className="row">
                <table className="table table-strip col-5">
                  <thead>
                    <tr nameClass="head">
                      <td scope="col">{`${t('overview.id')}`}</td>
                      <td scope="col">{`${t('overview.amount')}`}</td>
                      <td scope="col">{`${t('overview.date')}`}</td>
                      <td scope="col">{`${t('overview.time')}`}</td>
                    </tr>
                  </thead>
                  <tbody>{historyList}</tbody>
                </table>
              </div>
            </div>
          ) : (
            <Spinner
              animation="border"
              role="status"
              className="loader"
            ></Spinner>
          )}
        </div>
      </div>
    );
};

export default BalanceHistory;
