import React from "react";
import { supportdata } from "./../../Container/2FA-Alldata";
import { useTranslation } from "react-i18next";

import "./Support.css";


const Support = () => {
  const { t } = useTranslation();
  
  return (
    <div className="support-main">
      <div className="support-first-section">
        {/* <h1 className="top-heading">{`${t(supportdata.main_title)}`}</h1> */}
        <h1 className="top-heading">{`${t('support.title')}`}</h1>
        <p
          className="des-content"
          dangerouslySetInnerHTML={{ __html: t('support.description') }}
        ></p>
        <div className="support-second-section">
          <h2 className="support-headings">{`${t('support.contact_us')}`}</h2>
          <p className="remove-top-space">{`${t('support.destwo')}`}</p>
          <p dangerouslySetInnerHTML={{ __html: t('support.desthree') }}></p>
          <p>{`${t('support.desfour')}`}</p>
          <h2 className="note-heading">{`${t('support.note')}`}</h2>
          <p className="remove-top-space">{`${t('support.desfive')}`}</p>
        </div>
      </div>
    </div>
  );
};

export default Support;
