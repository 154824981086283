import { useState } from "react";

export const languages = [
  { locale: "en", name: "English" },
  { locale: "es", name: "Español" },
  { locale: "ar", name: "العربية" },
  { locale: "fr", name: "Français" },
  { locale: "zh", name: "简体中文" },
  { locale: "ru", name: "русский" },
];

export const locales = languages.map((language) => {
  return language.locale;
});

export const useLocationLocale = () => {
  return locales.find((locale) => {
    return window.location.pathname.startsWith(`/${locale}`);
  });
};

export const useLocalStorageLocale = () => {
  return locales.find((locale) => {
    return locale === localStorage.getItem("i18nextLng");
  });
};

export const useLocale = () => {
  const locationLocale = useLocationLocale();
  const localStorageLocale = useLocalStorageLocale();

  const [locale] = useState(() => {
    return locationLocale || localStorageLocale || "en";
  });

  return { locale };
};

export const useBasename = () => {
  const { locale } = useLocale();
  const { basename } = { basename: `/${locale || ""}` };

  if (!window.location.pathname.startsWith(basename)) {
    window.location.replace(
      basename +
        (window.location.pathname !== "/" ? window.location.pathname : "")
    );
  }

  return { basename };
};

export const useLanguage = () => {
  const { locale = "en" } = useLocale();
  const language =
    languages.find((language) => {
      return language.locale === locale;
    }) || languages[0];

  return { language };
};
