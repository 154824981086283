import one from "../images/one.svg";
import two from "../images/two.svg";
import three from "../images/three.svg";
import four from "../images/four.svg";
import right from "../images/right.svg";
import detailed from "../images/detailed.svg";
import bulb from "../images/bulb.svg";
import loyalty from "../images/loyalty.svg";
import lock from "../images/lock.svg";
import cash from "../images/cash.svg";
import friend from "../images/friend.svg";
import { Link } from "react-router-dom";

// Overview data
export const overview_first = {
  title: "Virtual Phone Numbers for Receiving SMS Messages",
  firstline:
    "Virtual phone numbers enable you to receive SMS messages without the need for a physical phone or SIM card. Our virtual numbers are temporary and help you bypass SMS verification procedure for social media, messaging apps, and other websites.",
  secondline:
    "Our virtual phone number service allows you to receive an unlimited number of SMS messages from any chosen site or app, anytime and anywhere. The process is fully automated and you'll receive verification codes instantly.",
  thirdline:
    "<span class='two-fa'>2FA</span><span class='two-pva'>PVA</span> helps you bypass country restrictions on certain websites and is suitable for anyone who needs to create a second account on various platforms, including WhatsApp, Instagram, Google, Telegram etc.",
};

export const overview_second = {
  title: "How Can I Obtain a Virtual Number for SMS Verification Code ?",
  description:
    "To easily set up a virtual phone number for SMS verification code, you can follow these simple steps:",
  stepdata: [
    { steplogo: one, stepname: "Sign up for an account" },
    { steplogo: two, stepname: "Select the country" },
    {
      steplogo: three,
      stepname: "Select the desired service and click on BUY button",
    },
    { steplogo: four, stepname: "Wait for the OTP confirmation SMS" },
  ],
  // destwo: `New to this type of service? Try 2FAPVA for free with our ${<Link to="?tab=Instructions">free online phone numbers</Link>} or study our <a href="/help">FAQ.</a>`,
  destwo:
    "New to this type of service? Try 2FAPVA for free with our <a href='instructions'>free online phone numbers</a> or study our <a href='/help'>FAQ.</a>",
};

export const overview_third = {
  title:
    "Choose A Convenient Option For Using A Virtual Number To Get A Password Verification Account (PVA):",
  activationdata: [
    {
      logo: right,
      description:
        "<b>Activations</b> - the time of use of a disposable number is limited to 20 minutes.",
    },
    {
      logo: right,
      description:
        "<b>Rent</b> - You can receive an unlimited number of messages for a period of 4 hours to 4 weeks.",
    },
  ],
};

export const overview_fourth = {
  title:
    "Discover the Key Advantages and Benefits of <span class='two-fa-head'>2FA</span>PVA :",
  discoverdata: [
    {
      logo: detailed,
      heading:
        "<b>Detailed Statistics</b> of all your assets for all countries and services",
    },
    {
      logo: bulb,
      heading:
        "<b>More than 100,00 online number</b> everyday from all over the world.",
    },
    {
      logo: loyalty,
      heading: "<b>Loyalty program</b> for those who makes regular purchases.",
    },
    {
      logo: lock,
      heading: "<b>Quick and automatic</b> password for virtual numbers.",
    },
    { logo: cash, heading: "<b>You only pay for SMS</b>. We will refund the minimum commission to you from all the most popular payment systems automatically." },
    {
      logo: friend,
      heading:
        "<b>Minimum commissions</b> from all the most popular payment systems in automatic mode.",
    },
  ],
  desone:
    "For further assistance, please visit our <a href='/help'>Help and Support</a> page for detailed information.",
  destwo:
    "We have the largest options of payment systems: <b>Visa, Master Card, Paypal , USDT, BTC, XMR , ETH, and many others ,</b> working in automatic mode. <span class='number-json'>Sign up for a virtual phone number today!</span>",
};

// Inststructions data
export const instructions_one = {
  title: "Activate Virtual SMS number: Instructions and Rules",
  description:
    "Welcome to our SMS receiving virtual phone number service! We're excited to offer you a reliable and easy-to-use solution for receiving SMS messages. Please take a moment to read through the following instructions and rules to ensure that you have a great experience with our service.",
};

export const instructions_two = {
  title:
    "Activating your virtual phone number for SMS receiving is quick and easy. Follow these simple instructions to get started :",
  activating: [
    { step: "Sign up for an account on our website." },
    { step: "Verify your email address and phone number." },
    {
      step: "Log in to your account and select a country and the desired service.",
    },
    { step: "Customize your SMS receiving preferences to suit your needs." },
    { step: "Your virtual phone number is now active and ready for use." },
  ],
};

export const instructions_three = {
  title: "Rules and regulations for using our virtual phone number service :",
  rules: [
    {
      step: "Virtual phone numbers are for personal and business use only. They cannot be used for illegal or fraudulent activities.",
    },
    {
      step: "We reserve the right to terminate or suspend your service if we believe it is being used in a manner that violates our terms of service.",
    },
    {
      step: "We do not tolerate spam or unsolicited messaging. If your virtual phone number is used for these activities, we will terminate your service.",
    },
    {
      step: "You are responsible for keeping your account information, including your password, secure.",
    },
    {
      step: "You must comply with all applicable laws and regulations when using our virtual phone number service.",
    },
    {
      step: "By using our virtual phone number service, you agree to our terms of service and privacy policy.",
    },
    {
      step: "We are not liable for any errors or issues that arise from incorrect use or programming of the API interface. This includes any errors that result from the user's misinterpretation of the API protocol. Refunds will not be provided for such software errors caused by the user.",
    },
  ],
};

export const instructions_four = {
  title: "Privacy and Security :",
  privacy: [
    {
      step: "We take the privacy and security of our customers very seriously. We use state-of-the-art encryption to protect your personal and financial information.",
    },
    {
      step: "We will never share your personal information with any third party without your explicit consent.",
    },
  ],
  desone:
    "Our SMS receiving virtual phone number service offers an easy and efficient solution for receiving SMS messages. We have provided clear instructions on how to activate and use the service, as well as the rules and regulations that must be followed when using it.",
  destwo:
    "By following the instructions provided, users can activate and customize their virtual phone numbers to suit their needs and start receiving SMS messages in no time. It's important to remember the rules and regulations to ensure a positive experience and to keep the service reliable and secure.",
  desthree:
    "Our virtual phone number service also comes with the assurance of our customer support team available 24/7 to help with any queries or issues that may arise. We take your privacy and security seriously, and we will never share your personal information with any third party without your explicit consent.",
  desfour:
    "Thank you for choosing our SMS receiving virtual phone number service. We look forward to helping you stay connected and receiving messages in a more convenient way.",
  desfive:
    "If you have any questions or concerns about activating or using your virtual phone number, please contact our <a href='/support'>customer support team.</a> We're here to help!",
};

// Support data

export const supportdata = {
  main_title: "Help and Support",
  desone:
    "If you have any questions or need assistance, please refer to our <a href='/help'>FAQ</a> page for helpful information.",
  contact_us: "Contact Us",
  destwo:
    "If you can't find the answer to your question in our FAQ, please don't hesitate to reach out to our support team.",
  desthree:
    "You can contact us by email at <a href='mailto:two.fapvasupport@protonmail.me'>two.fapvasupport@protonmail.me</a> or click on the telegram button in the header and send us a message, we'll be glad to help you!",
  desfour:
    "Our team is available 24/7 and we'll do our best to respond to your inquiry as soon as possible.",
  note: "Note",
  desfive:
    "Please include your account email and a detailed description of the issue you are facing, and your virtual phone number if related. This will help us to resolve your issue more efficiently.",
};

export const suppoPrivacyPolicyrtdata = {
  main_title: "Privacy Policy",
  desone:
    "At 2FAPVA, we understand the importance of safeguarding the personal information of our users. We are fully committed to maintaining the confidentiality and security of all personal information collected through our services. We will not disclose, share or sell any of your personal information to third parties unless it is required by law. We employ robust security measures to ensure the safety and integrity of your personal information at all times. Trust in us to protect your privacy.",
  contact_us: "Personal Data Storage",
  destwo:
    "At 2FAPVA, we are committed to providing you with the best possible service experience, which requires us to collect some information about you. The type of information we collect will depend on how you use our service. We will collect the necessary information at the time of installation, login, or use of our services.",
  desthree:
    "We are committed to obtaining your informed consent for the collection and processing of your personal data. With your permission, we may collect and process the following information: name and surname, email address, phone number, and bank card information. Rest assure that we will handle your personal information with care and only process it to the extent necessary to provide you with our service.",
  title1: "Personal Data Storage",
  desfour:
    "We use the information we collect to provide and improve our service. This includes using your virtual phone number to route SMS messages to you and using your usage information to troubleshoot any issues you may encounter. We may also use your information to contact you with important updates or important information about your account.",
  title2: "Sharing Your Information",
  desfive:
    "We do not share your personal information with any third parties, except in the following cases : ",
  dessix1: "•	When required by law or to comply with legal process ",
  dessix2: "•	To protect the rights and safety of our company and our users ",
  dessix3: "•	With your express consent ",
  title3: "Cookies Processing",
  Cookiesdes1:
    "At 2FAPVA.com, we employ the use of cookies to optimize your browsing experience and ensure that the content on our website is properly displayed. These cookies are small data files that are stored on your device, and help our website remember information about your preferences and browsing history. This can include information such as your preferred language, and which pages you have previously viewed on our website. ",
  Cookiesdes2:
    "By utilizing these cookies, we strive to make your browsing experience on our website as seamless and convenient as possible. You can learn more about cookies and how they are used on our website, and also manage your cookie settings in your browser. However, please be aware that disabling cookies may affect the functionality of our website, and certain features may not be available to you. ",
  Cookiesdes3:
    "In summary, we use cookies to make your experience on 2FAPVA.com more personalized and convenient, while maintaining your privacy and security.",
  title4: "Security",
  Securitydes:
    "We take the security of your personal information seriously and have implemented appropriate measures to protect it from unauthorized access, use, or disclosure.",
  title5: "Changes to Our Privacy Policy",
  Changesdes1:
    "We may update our Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons.",
  title7: "Contact Us",
  Contactdes:
    "If you have any questions about our Privacy Policy or the information we collect, please contact us at two.",
  Email: "fapvasupport@protonmail.me",
};

export const PaymentPolicydata = {
  main_title: "About Registration",
  desone:
    "The Company is responsible for managing client account balances and ensuring the safe and secure handling of funds. Clients have the right to request available funds at any time. The official methods for depositing and withdrawing funds are listed on the company website. Clients are responsible for any risks associated with using these payment methods. The company is not liable for any issues related to the payment methods. In case of fraud, the company reserves the right to cancel transactions and freeze the client's account. Technical errors may result in the cancellation of transactions and suspension of financial activity on the company website.",
  contact_us: "Deposit Procedure",
  destwo:
    "By signing up on the Website, clients consent to the handling of their personal information in accordance with the Privacy Policy. This consent is given to facilitate the provision of services, enhance user experience, streamline communication, safeguard funds and prevent fraudulent activities.",
  desthree:
    "Clients may revoke this consent at any time by sending a request to two.fapvasupport@protonmail.me. The data we collect includes the client's email address and usage statistics through cookies. All personal data shared is kept confidential and will only be shared with third parties with the client's consent or as required by legal authorities.To register, clients are required to provide their contact information, agree to the terms of the Agreement, and complete the registration process.",
  desfour:
    "To make a deposit, the client must initiate a request from their personal profile. To complete the request, the client must specify the deposit amount in any of the supported currencies. The processing time for the request is dependent on the workload of the payment provider, and the company is not able to influence this processing time. When you click the  button, the transaction is considered final and cannot be reversed. By placing an order on the Website, you confirm that your actions do not violate any local laws. ",
  button: "Deposit",
  desfive:
    "Additionally, by accepting these terms, you, as the cardholder, confirm that you have the authority to use the services offered on the Website. You acknowledge that as the cardholder, you are responsible for paying for all goods and services requested through the Website and any additional expenses/fees that may apply. If you do not agree with these terms or have other concerns, please do not proceed with the payment and contact the Website administrator or support team directly.",
};

export const ReturnPolicydata = {
  desone:
    "2FAPVA does not issue refunds for virtual number purchases that have been successfully activated. However, we understand that exceptional circumstances may arise. Therefore, we will honor refund requests under the following",
  destwo: "Conditions:",
  desthree:
    "• Non-receiving of SMS, due to server issues with a service, must be reported within 7 days of purchase for assistance.",
  desfour:
    "• Invalid virtual number issues, with evidence of invalid number provided, must be reported within 7 days of purchase for a refund.",
  desfive:
    "• Major defects, with a reported issue and failure to correct within 72 hours, are eligible for a full refund or replacement.",
  dessix:
    "• Temporary access to your webhost/server may be requested by our technicians to identify and fix issues. Failure to provide access or refusal to do so will result in ineligibility for a refund.",
};

export const Termsusedata = {
  desone:
    "The following terms and definitions apply to the following meanings :",
  destwo:
    "- As the user accessing this website,'Client' pertains to 'you' and 'your' agreement to abide by the terms and conditions set forth by the company. The terms You and Your also refer to you, the individual utilizing the website and adhering to the established guidelines of the website.",
  desthree:
    "- “API service” is a set of ready-made technical solutions by the Provider to be used in the Client's external software products.",
  desfour:
    "- “Refund operation” - the Client’s actions to refund the payment or a part of the payment to the Client in connection with the refund (cancellation, refusal) by the Client of/from services as well as because of the failure by the Provider to provide services to the Client that have been previously paid by the Client.",
  desfive:
    "Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.",
  title: "Subject Agreement",
  Subjectdes1:
    "1- This Agreement governs the provision of intermediary services by the Consultant to the Client, allowing for technical access to read SMS messages received on the designated Active Number. The Client agrees to pay for the Services in accordance with the terms and conditions stated herein.",
  Subjectdes2:
    "2- The pricing and duration of the Services, as well as the registration form, can be found on the Website.",
  Subjectdes3:
    "3- The Client acknowledges that, under this Agreement, the Provider acts as an intermediary between the Client and the mobile network operator.",
  Subjectdes4:
    "4- The Client is entitled to use the Services under this Agreement in any country, provided this does not contravene the laws of the country. If the Client intends to use the Services in an area where the use of the Services is not allowed or prohibited by law, the Client has no right to enter into this Agreement and to use the Service.",
  Subjectdes5:
    "5- You must not republish, sell, rent, sub-license, or redistribute any material from 2FAPVA.",
  Subjectdes6:
    "By agreeing to this Contract, the Client affirms that their use of the Services is in compliance with the local laws of their place of residence.",
  title1: "Availability of Service",
  Servicedes:
    "The Company makes no guarantee of the availability of the Service and will not be liable for any interruptions to the Service. The Company reserves the right to modify, suspend, or discontinue the Service at any time without notice.",
  title2: "Privacy",
  Privacydes:
    "The Company respects your privacy and is committed to protecting your personal information. Any personal information that you provide to the Company will be subject to our Privacy Policy, which can be found on our website.",
  title3: "Limitation of Liability",
  Limitationdes:
    "The Company will not be liable for any damages resulting from the use of the Service, including but not limited to direct, indirect, incidental, consequential, or punitive damages.",
  title4: "General terms and conditions",
  Generaldes1:
    "• Services will be provided in accordance with the terms and conditions outlined in this Contract and any accompanying annexes, as well as any terms and conditions posted on the relevant website.",
  Generaldes2:
    "• If there are any conflicts between the terms and conditions posted on the website and those outlined in this Contract or its annexes, the terms and conditions posted on the website will take precedence.",
  Generaldes3:
    "• Services will only be provided to the Client under the following conditions : the Client has properly registered on the site and the Provider has confirmed their ability to provide the service by sending a message to the email provided during registration.",
  Generaldes4:
    "• The Services will be provided to the Client based on the Tariff and type of service selected by the Client. In order to prevent fraudulent activities within SMS Reception services, the Provider may require User identification. This can be done by submitting and verifying ID data when paying for services via certain payment services.",
  title5: "Changes to Terms and Conditions",
  Changesdes:
    "The Company reserves the right to make changes to these terms and conditions at any time. Your continued use of the Service following any changes to these terms and conditions will constitute your acceptance of such changes.",
};

export const faq_data = {
  faq: {
    title: "Frequently Asked Questions",
    sub_title: "How can we help you?",
    questions: {
      question1: "How can I receive my text messages online with my number ?",
      answer1:
        "In order to select a temporary phone number for SMS verification, follow these steps:",
      step1:
        "First, select the country from the list provided on the left side of the screen.",
      step2:
        "Next, choose a service that is compatible with the website you wish to register on.",
      step3:
        "Then, click the 'buy' button and copy the number that appears in the order.",
      step4:
        "Finally, use this number to register your account and wait for the SMS verification to arrive. The code will be displayed on the screen for you to use",
      question2: "How can I receive text messages online for free ?",
      answer2:
        "In order to receive text messages online for free, follow these steps :",
      step11: "Begin by navigating to the 'Free Numbers' section of the menu.",
      step22:
        "Next, select one from the list below on our website and activate it.",
      step33:
        "If the desired numbers are not currently available, we recommend checking back at a later time to see if they have become available.",
      question3: "Sms is still not arrived, what to do ?",
      answer3:
        "If you are not able to receive the SMS verification code within 5 minutes, it is recommended that you cancel the order and request a refund. Then, you can place a new order for a different temporary phone number. Please note that in some cases, if the phone numbers are not working, you should try ordering several different numbers to increase the chances of receiving the SMS verification code. We recommend trying with at least 5 different numbers. It's also important to verify your account with the service provider and make sure you are following their policies, as sometimes there might be an issue with the service provider side and not with your temporary number.",
      question4: "How to contact 2FAPVA support ?",
      answer4:
        "You can contact us by email at <a href='mailto:two.fapvasupport@protonmail.me'>two.fapvasupport@protonmail.me</a> or click on the telegram button in the header and send us a message, we'll be glad to help you! Our team is available 24/7 and we'll do our best to respond to your inquiry as soon as possible.",
      question5:
        "Why Virtual Phone Numbers are Essential for SMS Receiving and Verification ?",
      answer5:
        "Virtual Phone Numbers are Essential for SMS Receiving offer a variety of benefits, including the ability to register multiple accounts, maintain privacy, avoid scams, get discounts and bypass restrictions. Create unlimited profiles on social media and other platforms and avoid unwanted paid services and subscriptions! If you're a business, social media manager, or just looking to promote your projects, our virtual phone numbers are the perfect tool for you. Not only do they keep your information safe and allow you to remain anonymous online, but they also protect you from scams and help you take advantage of discounts and promotions. Plus, with our virtual numbers, you can access restricted sites and services from anywhere in the world.",
      question6: "What are the benefits of using virtual phone numbers?",
      answer6:
        "Virtual phone numbers offer a range of benefits, including increased flexibility, enhanced security and privacy, improved customer service, and ease of use. With virtual phone numbers, you have the ability to forward calls and messages, including SMS, to any phone or device of your choice, giving you the freedom to work from anywhere, at any time.Additionally, virtual phone numbers can be used to set up two-factor authentication for online accounts, and SMS receiving services can be used to improve customer service. Overall, virtual phone numbers offer a high level of flexibility and convenience, allowing you to stay connected and productive no matter where you are. If you're interested in using a virtual phone number service, we recommend checking out 2FAPVA. They offer a variety of options to expand your business connections and you can easily create a free account by simply entering your email and following the prompts.",
      question7: "How can I create a Bigo Live account without a phone number?",
      answer7:
        "To create a Bigo Live account without a phone number, you can use a virtual phone number service such as 2FAPVA. To get started, you can go to the 2FAPVA website, register with a valid email and password, and then top up your balance choosing your ideal payment method. Next, you can select your country of origin or the country where you want to assign the temporary number, and then use the search function to find the Bigo Live platform. After purchasing a temporary phone number, you can wait to receive the verification code and use it to complete your registration. Repeat the process if you need to register multiple accounts. Using virtual numbers will allow you to register with known online services without revealing your personal phone number.",
      question8: "How can I use a virtual number to answer calls?",
      answer8:
        "To use a virtual number to answer calls, you can use a virtual number service. First, you would need to sign up for an account with the virtual number provider. Then, you would need to top up your balance with the necessary amount to receive a call. Next, you can select a country and operator from the list provided by the service. After that, you can find the option to 'Receive a CALL-reset' and purchase a virtual number. Once you have the virtual number, you can use it to receive calls by providing it in the registration field of the service you want to use it for. The cost of receiving a call may vary depending on the country you choose and the service provider you use.",
      question9:
        "What are virtual numbers and how can they be used to receive SMS?",
      answer9:
        "Virtual numbers are telephone numbers that are not tied to a physical phone line and can be used to receive SMS messages online. They are often used to register for additional accounts on social networks and messaging platforms, as well as to access foreign websites and apps that are restricted in certain regions. Virtual numbers can also be used to receive discounts on delivery and ride-sharing services, and can be useful for merchants on bulletin boards and trading platforms.To use a virtual number to receive SMS, users can go to a virtual number provider's website, like, and create an account. They can then deposit money into their account balance and select a country and operator from the list provided. After purchasing a virtual number, users can then provide it in the registration field for the service they want to use it for and receive SMS messages on that number.",
    },
  },
};

